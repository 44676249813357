import styles from './CallsToAction.module.scss'
import { ButtonLink } from '~/components/primitives/Button/ButtonLink'
import { useClickCTAAnalytics } from '~/components/hooks/cms/uci/useClickCTAAnalytics'
import type { AnalyticsContext, CallToAction as CallsToActionType } from '~/lib/client-server/cms/modules'

export interface CallToActionProps {
	/** Analytics data for tracking purposes */
	context?: AnalyticsContext
	/** Collection of call-to-action items for use to present the button(s) */
	actions?: CallsToActionType[]
	/** will render the call-to-action buttons as text links. */
	isTextLink?: boolean
	/** Optional class to extend styling for this component */
	className?: string
}

/**
 * CallToAction component is a reusable component that renders a list of call-to-action buttons.
 */
const CallsToAction = ({ actions, isTextLink = false, context, className = '' }: CallToActionProps) => {
	const { handleCTAClick } = useClickCTAAnalytics(context)
	const variant = isTextLink ? 'text' : 'primary'

	return (
		<ul className={`${styles.ctas} ${className}`}>
			{actions?.map((link, index) => {
				return (
					link.url && (
						<li key={index} className={styles['ctas__list-item']}>
							<ButtonLink href={link.url} onClick={handleCTAClick} className={styles['cta-button']} variant={variant}>
								{link.text}
							</ButtonLink>
						</li>
					)
				)
			})}
		</ul>
	)
}

export default CallsToAction
