import logger from '~/lib/logger'
import type { DeviceType } from './module'
import { CmTeaserImpl } from './teaser'
import { CmMediaImpl } from './media'
import { HeroStyle } from '~/lib/client-server/cms/modules/hero'
import { CmFeaturedProductsImpl } from '~/lib/client-server/cms/sources/coremedia/shared/featured-products'
import type { FeaturedProducts } from '~/lib/client-server/cms/modules/feature-products'
import type { AnalyticsTracking } from '~/lib/client-server/cms/modules'

export class CmHeroImpl extends CmTeaserImpl {
	// This concept could likely be applied to future modules but the returned results would be module style specific.
	get moduleStyle() {
		switch (this.raw.viewtype) {
			case 'hero-overlay':
			case 'hero-overlay-transparent':
				return HeroStyle.Overlay
			case 'hero-mini':
			case 'hero01-mini':
				return HeroStyle.Mini
			case 'dt-category-hero':
				return HeroStyle.CategoryHero
			case 'hero-plp':
				// reusing overlay template to render image only category hero
				return HeroStyle.Overlay
			case 'hero-split':
			case 'hero-split-dark':
				return HeroStyle.Split
		}
		logger.warn(`No module style found`)
		return undefined
	}

	getImages(deviceType: DeviceType): CmMediaImpl[] | undefined {
		const image = this.getMedia(deviceType)
		if (!image) {
			logger.warn(`No image associated with hero module "${this.getModuleName()}"`)
			return undefined
		}
		return image.map((x) => new CmMediaImpl(x))
	}

	getFeaturedProducts(): FeaturedProducts | null {
		if (this.raw.related?.length) {
			const featuredProductData = new CmFeaturedProductsImpl(this.raw)
			return featuredProductData.toUniversal()
		}
		return null
	}

	getAnalyticsTracking(): AnalyticsTracking {
		const desktopImages = this.getImages('desktop')
		return {
			header: this.getHeader(),
			asset_name: desktopImages
				? {
						id: desktopImages[0].id,
						src: desktopImages[0].src,
						alt: desktopImages[0].alt,
						height: desktopImages[0].height,
						width: desktopImages[0].width,
				  }
				: undefined,
			module: 'hero',
			placement: 'hero',
			snipe: this.getSnipeText(),
		}
	}
}
