/* eslint-disable no-underscore-dangle */
import { createClientLogger } from '~/lib/logger'
import { HeroStyle } from '~/lib/client-server/cms/modules/hero'
import { type McpBaseSafe, McpBaseSchema } from './schema'
import { McpImagesTypes } from '~/lib/client-only/mcp/mcp.constants'
import type { AnalyticsTracking, ImageReference } from '~/lib/client-server/cms/modules'

const logger = createClientLogger('mcp')

enum TextStyle {
	Left = 'Left',
	Center = 'Center',
}

export class McpHeroImpl {
	private _parsedData: McpBaseSafe | undefined

	constructor(data: unknown) {
		try {
			this._parsedData = McpBaseSchema.parse(data)
		} catch (e) {
			logger.error(e, 'Failed to parse data using McpBaseSchema')
		}
	}

	protected get raw() {
		return this._parsedData
	}

	get moduleStyle() {
		switch (this.raw?.heroStyle) {
			case 'HeroOverlay':
				return HeroStyle.Overlay
			case 'HeroMini':
				return HeroStyle.Mini
		}
		logger.error(this.raw, 'No module style found')
		return undefined
	}

	// MCP returns a value or "Dark" or "Light" based on what color the text should be, we are inverting this to create the theme.
	// A "Dark" theme should have light text and dark background. We still want the light theme to be the default.
	get moduleTheme() {
		if (this.raw?.backgroundColor?.toLocaleLowerCase() === 'light') {
			return 'dark'
		}
		return 'light'
	}

	get textStyleCenter(): boolean {
		return this.raw?.textStyle === TextStyle.Center // textStyle returns true if it is Centered
	}

	get backgroundColor() {
		return this.raw?.backgroundColor
	}

	get campaign() {
		return this.raw?.campaign
	}

	get ctaText() {
		return this.raw?.ctaText
	}

	get ctaTextSecondary() {
		return this.raw?.ctaTextSecondary
	}

	get ctaUrl() {
		return this.raw?.ctaUrl
	}

	get ctaUrlSecondary() {
		return this.raw?.ctaUrlSecondary
	}

	get desktopImageURL() {
		return this.raw?.desktopImageURL
	}

	get desktopImageAlt() {
		return this.raw?.desktopImageURL ?? ''
	}

	get desktopImageCTAURL() {
		return this.raw?.desktopImageCTAURL
	}

	get experience() {
		return this.raw?.experience
	}

	get header() {
		return this.raw?.header
	}

	get headerStyle() {
		return this.raw?.headerStyle
	}

	get heroStyle() {
		return this.raw?.heroStyle
	}

	get mobileImageURL() {
		return this.raw?.mobileImageURL
	}

	get mobileImageAlt() {
		return this.raw?.mobileImageAtl ?? ''
	}

	get mobileImageCTAURL() {
		return this.raw?.mobileImageCTAURL
	}

	get subheader() {
		return this.raw?.subheader
	}

	get snipeText() {
		return this.raw?.snipeText
	}

	get textStyle() {
		return this.raw?.textStyle
	}

	get transparent() {
		return this.raw?.transparent
	}

	get userGroup() {
		return this.raw?.userGroup
	}

	get width() {
		return this.raw?.width
	}

	get ctaLinks() {
		return this.raw?.ctaLinks
	}

	get posX() {
		const posX = this.raw?.calloutPosition ?? 'bottom-right'
		// textbox / content positioning is on desktop only
		if (posX.indexOf('left') > -1) {
			return 'unset'
		}
		if (posX.indexOf('right') > -1) {
			return 'end'
		}
		return 'center'
	}

	get posY() {
		const posY = this.raw?.calloutPosition ?? 'bottom-right'
		// textbox / content positioning is on desktop only
		if (posY.indexOf('top') > -1) {
			return 'unset'
		}
		if (posY.indexOf('bottom') > -1) {
			return 'end'
		}
		return 'center'
	}

	get ctaTargets() {
		const targets: {
			text: string
			url: string
		}[] = []

		if (this.raw?.ctaText && this.raw?.ctaUrl) {
			targets.push({
				text: this.raw.ctaText,
				url: this.raw.ctaUrl,
			})
		}
		if (this.raw?.ctaTextSecondary && this.raw?.ctaUrlSecondary) {
			targets.push({
				text: this.raw.ctaTextSecondary,
				url: this.raw.ctaUrlSecondary,
			})
		}
		return targets
	}

	get image(): ImageReference {
		return {
			id: this.desktopImageURL,
			src: this.desktopImageURL,
			alt: this.desktopImageAlt,
			height: this.imageDimensions('hei', McpImagesTypes.DESKTOP),
			width: this.imageDimensions('wid', McpImagesTypes.DESKTOP),
			ctaLink: this.desktopImageCTAURL,
		}
	}

	get analyticsTracking(): AnalyticsTracking {
		return {
			header: this.raw?.header,
			asset_name: this.image,
			module: 'hero',
			placement: 'hero',
			snipe: this.snipeText,
		}
	}

	imageDimensions(dimension: string, imageType: McpImagesTypes): number {
		let url: string
		switch (imageType) {
			case McpImagesTypes.DESKTOP:
				url = this.raw?.desktopImageURL ?? ''
				break
			case McpImagesTypes.MOBILE:
				url = this.raw?.mobileImageURL ?? ''
				break
			default:
				logger.error(`Invalid image type: ${imageType}`)
				throw new Error('Invalid image type')
		}
		const params = new URLSearchParams(url)

		if (dimension === 'hei') {
			const height = parseInt(params.get('hei') ?? '', 10)
			if (height === null || Number.isNaN(height)) {
				return 0
			}
			return height
		}

		const width = parseInt(params.get('wid') ?? '', 10)
		if (width === null || Number.isNaN(width)) {
			return 0
		}
		return width
	}
}
