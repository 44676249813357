import { useState, useRef, useMemo, useCallback } from 'react'

/**
 * Define the possible statuses of the timer.
 */
export type TimerStatus = 'idle' | 'running' | 'paused' | 'finished'

/**
 * Define the shape of the timer state.
 */
export interface TimerState {
	status: TimerStatus
}

/**
 * Custom hook that takes a timeout (in milliseconds) as input.
 * @param timeout The duration for the timer in milliseconds.
 * @param autoStart Whether the timer should start automatically when the component mounts.
 * @returns The current timer state and control functions to start, pause, and reset the timer.
 * @example
 * import useTimer from '~/components/hooks/useTimer';
 *
 * const MyComponent = () => {
 *  const { timerState, startTimer, pauseTimer, resetTimer } = useTimer(60000);
 *  return (
 *    <div>
 * 		<p>{timerState.status}</p>
 *      <button onClick={startTimer}>Start</button>
 *      <button onClick={pauseTimer}>Pause</button>
 *      <button onClick={resetTimer}>Reset</button>
 *    </div>
 *   );
 * };
 */
const useTimer = (timeout: number, autoStart = true) => {
	const [timerState, setTimerState] = useState<TimerState>({
		status: 'idle',
	})

	const intervalRef = useRef<ReturnType<typeof setTimeout> | null>(null)
	const startTimeRef = useRef<number | null>(null)

	/**
	 * Starts the timer. Updates the timer state every second.
	 */
	const startTimer = useCallback(() => {
		if (intervalRef.current !== null) return

		startTimeRef.current = Date.now()
		setTimerState((prevState) => ({
			...prevState,
			status: 'running',
		}))

		if (typeof window !== 'undefined') {
			intervalRef.current = setTimeout(() => {
				setTimerState(() => {
					return {
						status: 'finished',
					}
				})
			}, timeout)
		}
	}, [timeout])

	/**
	 * Pauses the timer. Stops the interval and updates the status.
	 */
	const pauseTimer = useCallback(() => {
		if (intervalRef.current === null) {
			return
		}

		clearInterval(intervalRef.current)
		intervalRef.current = null

		setTimerState((prevState) => ({
			...prevState,
			status: 'paused',
		}))
	}, [])

	/**
	 * Resets the timer. Clears any running interval and resets the state to initial values.
	 */
	const resetTimer = useCallback(() => {
		if (intervalRef.current !== null) {
			clearInterval(intervalRef.current)
			intervalRef.current = null
		}
		startTimeRef.current = null

		setTimerState({
			status: 'idle',
		})
	}, [])

	useMemo(() => {
		if (autoStart && timerState.status === 'idle') {
			startTimer()
		}
	}, [autoStart, startTimer, timerState.status])

	return { timerState, startTimer, pauseTimer, resetTimer }
}

export default useTimer
