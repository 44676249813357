import { memo } from 'react'
import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

function PlayOutlineIcon(props: IconProps) {
	return (
		<svg viewBox="0 0 32 32" fill="none" {...getEnhancedIconProps(props)} xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.16 30.88C6.09408 30.9294 6 30.8824 6 30.8L6 1.2C6 1.1176 6.09408 1.07056 6.16 1.12L25.8933 15.92C25.9467 15.96 25.9467 16.04 25.8933 16.08L6.16 30.88Z"
				stroke="currentColor"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default memo(PlayOutlineIcon)
