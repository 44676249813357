'use client'

import { useMediaQuery } from '~/components/hooks/useMediaQuery'
import clsx from 'clsx'
import { memo } from 'react'
import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import cssVariables from '~/styles/variables.module.scss'
import { moduleSettingsMap } from '../module-settings-map'
import CallToAction from './CallToAction'
import Headline from './Headline'
import Icon from './Icon'
import SnipeText from './SnipeText'
import Subheadline from './Subheadline'
import styles from './Teaser.module.scss'

interface Props {
	data: CmTeasable
	enableCustomTeaser?: boolean
	locale: string
}

const Teaser = ({ data, locale, enableCustomTeaser = false }: Props) => {
	const mobileLayoutSettings = data?.uaMobileLayoutSettings
	const mobileTeaserOverlay = data?.uaModuleSettings?.mobileTeaserOverlay
	const desktopTeaserOverlay = data?.uaModuleSettings?.teaserOverlay
		? data.uaModuleSettings?.teaserOverlay
		: data.teaserOverlaySettings

	/**
	 * Determine if there is an overlay text box, or text box wrapper around text
	 */
	// TO DO - WE ARE CONDITIONALLY SEARCHING AND RENDERING SOME THINGS IN HERE - BUT SOME POSITIONING DOESNT NEED TO BE SET, SOME MODULES HAVE DATA COMING THROUGH THEIR SETTINGS ON MODULESETTINGSMAP
	const useDesktopTextBoxOverlay =
		data?.viewtype && moduleSettingsMap?.[data.viewtype]?.desktopCopyOverlayWrapper
			? !!moduleSettingsMap[data.viewtype].desktopCopyOverlayWrapper
			: false
	const desktopFullWidthCopy =
		data?.viewtype && moduleSettingsMap?.[data.viewtype]?.desktopFullwidthCopy
			? !!moduleSettingsMap[data.viewtype].desktopFullwidthCopy
			: false
	const useDesktopCssWidth =
		data?.viewtype && moduleSettingsMap?.[data.viewtype]?.useCssDefinedWidth
			? !!moduleSettingsMap[data.viewtype].useCssDefinedWidth
			: false
	const useMobileTextBoxOverlay: boolean = mobileLayoutSettings?.enableTeaserOverlay
		? mobileLayoutSettings.enableTeaserOverlay
		: false
	const mobileTextBoxInlineWrapper: boolean = mobileLayoutSettings?.enableTeaserOverlayInlineWrapper
		? mobileLayoutSettings.enableTeaserOverlayInlineWrapper
		: false

	/**
	 * Grab the information to adjust the overlay positioning for desktop and mobile
	 */
	let mPosX
	let mPosY: string | undefined
	const mobilePosX = mobileTeaserOverlay?.positionX || 0
	const mobilePosY = mobileTeaserOverlay?.positionY || 0
	const mobileWidth = mobileTeaserOverlay?.width || 100
	const justify = 'justify'
	const align = 'align'

	const checkPos = (a, b) => {
		if (b === align) {
			if (a > 0) {
				mPosX = 'end'
			} else if (a < 0) {
				mPosX = 'start'
			} else {
				mPosX = 'center'
			}
		} else if (a > 0) {
			mPosY = 'end'
		} else if (a < 0) {
			mPosY = 'start'
		} else {
			mPosY = 'center'
		}
	}
	checkPos(mobilePosX, align)
	checkPos(mobilePosY, justify)

	const textOverlayMobile = useMobileTextBoxOverlay
		? {
				position: `absolute`,
				width: '100%',
				height: '100%',
				top: 0,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: `${mPosY}`,
				alignItems: `${mPosX}`,
		  }
		: {
				width: `${mobileWidth}%`,
				marginLeft: `auto`,
				marginRight: `auto`,
		  }

	const positionX: number = desktopTeaserOverlay?.positionX !== undefined ? desktopTeaserOverlay.positionX + 50 : 0
	const positionY: number = desktopTeaserOverlay?.positionY !== undefined ? desktopTeaserOverlay.positionY + 50 : 0

	const desktopWidthOverlay = desktopTeaserOverlay?.width ?? 100
	const desktopWidthFullOverride = desktopFullWidthCopy ? 100 : desktopWidthOverlay
	const desktopWidth = useDesktopCssWidth ? null : `${desktopWidthFullOverride}%` // CSS width comes as a percentage, so set all desktop width to percent here

	const textOverlayDesktop = useDesktopTextBoxOverlay
		? {
				position: `absolute`,
				width: desktopWidth,
				left: `${positionX}%`,
				top: `${positionY}%`,
				marginRight: `-${positionX}%`,
				marginBottom: `-${positionY}%`,
				transform: `translate(-${positionX}%, -${positionY}%)`,
		  }
		: { position: '', width: desktopWidth }

	const isDesktop = useMediaQuery(`(min-width:${cssVariables.mobileMenuQueryMin})`)

	const overlayDesktopCss = isDesktop ? textOverlayDesktop : {}

	const overlayMobileCss = isDesktop ? {} : textOverlayMobile

	const textAlignDesktop: string =
		desktopTeaserOverlay?.centerCopy ||
		(desktopTeaserOverlay?.positionX >= -17 && desktopTeaserOverlay?.positionX <= 16 && useDesktopTextBoxOverlay)
			? 'module__center-aligned'
			: 'module__left-aligned'

	const textAlignMobile: string = mobileTeaserOverlay?.centerCopy ? 'module__center-aligned' : 'module__left-aligned'

	const textAlign = isDesktop ? textAlignDesktop : textAlignMobile
	const overlayCss = {
		...overlayDesktopCss,
		...overlayMobileCss,
	}

	const desktopTextLinks = !!desktopTeaserOverlay?.useTextLinks || false
	const mobileTextLinks = !!mobileTeaserOverlay?.useTextLinks || false
	const mobileInlineWrapper = (data?.viewtype && moduleSettingsMap[data.viewtype]?.mobileInlineWrapper) !== undefined

	const desktopColumns =
		(desktopTeaserOverlay?.twoColumn || (data?.viewtype && moduleSettingsMap[data.viewtype]?.textOnly)) ?? false

	/**
	 * Check to see if content exists - if it does not, do not render the teaser overlay.
	 * This is causing issues with Featured Products and Hotspots
	 * */
	const hasTitle = !!(data?.teaserTitle || data?.teaserTextAsTree)
	const hasContent = !!(data?.snipeText || hasTitle || data?.subHeadlineTextAsTree || data?.teaserTargets?.length)
	if (!hasContent) return null

	const Content = ({ children = undefined }: { children?: JSX.Element }) =>
		desktopColumns ? (
			<>
				{data.snipeText && <SnipeText data={data.snipeText} />}
				<Headline data={data} locale={locale} />
				<div className="module__columns">
					<Subheadline data={data} locale={locale} />
					{children}
				</div>
			</>
		) : (
			<>
				{data.snipeText && <SnipeText data={data.snipeText} />}
				<Headline data={data} locale={locale} />
				<Subheadline data={data} locale={locale} />
				{children}
			</>
		)

	/**
	 * For <Icon>'s enableCustomIcon = this is coming from the same logic that triggers the custom teaser.
	 * Choosing the enableCustomIcon as the variable as a boolean we could pass in to use in a more dynamic solution.
	 */
	return mobileTextBoxInlineWrapper ? (
		<div
			className={clsx('module__copyarea', enableCustomTeaser && 'module--stickyMobileNav', textAlignDesktop)}
			style={mobileInlineWrapper ? {} : overlayDesktopCss}
		>
			<div
				className={`module__copyarea-text ${textAlignMobile}`}
				style={mobileInlineWrapper ? overlayDesktopCss : overlayMobileCss}
			>
				<Icon locale={locale} data={data} enableCustomIcon={enableCustomTeaser} />
				<Content />
			</div>
			<CallToAction
				locale={locale}
				parent={data}
				teaserTargets={data.teaserTargets}
				enableListWithCta={enableCustomTeaser}
				desktopTextLinks={desktopTextLinks}
				mobileTextLinks={mobileTextLinks}
				className={`${styles.teaser__list} ${styles['teaser__list--inline-wrapper']}`}
			/>
		</div>
	) : (
		<div
			id={data.id}
			className={clsx(
				'module__copyarea',
				textAlign,
				enableCustomTeaser && 'module--stickyMobileNav',
				desktopColumns && 'module--twoColumn',
			)}
			style={overlayCss}
		>
			<Icon data={data} locale={locale} enableCustomIcon={enableCustomTeaser} />
			<Content>
				<CallToAction
					parent={data}
					locale={locale}
					teaserTargets={data.teaserTargets}
					enableListWithCta={enableCustomTeaser}
					desktopTextLinks={desktopTextLinks}
					mobileTextLinks={mobileTextLinks}
					className={
						enableCustomTeaser
							? `${styles.teaser__list} ${styles['teaser__list--sticky-nav']}`
							: `${styles.teaser__list} ${styles['teaser__list--ingrid']}`
					}
				/>
			</Content>
		</div>
	)
}
export default memo(Teaser)
