'use client'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import clsx from 'clsx'
import useWindowDimensions from '~/components/hooks/useWindowDimensions'
import ElementTree from '~/components/shared/ElementTree'
import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import { getModuleLinkSettings } from '~/lib/cms/legacy/helper'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import styles from './InGrid.module.scss'
import { moduleSettingsMap } from './module-settings-map'
import Headline from './partials/Headline'
import MediaContent from './partials/MediaContent'
import Subheadline from './partials/Subheadline'
import Teaser from './partials/Teaser'

interface Props {
	data: CmTeasable
	additionalClasses?: string
	position: number
	placement?: string
	locale: string
}

const InGrid = ({ data, locale, position, additionalClasses = '', placement }: Props) => {
	const desktopLinkedStyle = () => {
		const classesDesktop: string[] = []

		/**
		 * This is looking up the viewtype - which corresponds with the CMTeaser type in CM. There are hidden/default params in the CM code that set basics of the module. Using the module-settings.map.ts to pull these settings in and assign the classes as it builds... this is likely a temp solution but should speed up the dev process on the blocks
		 *
		 * THIS SHOULD BE EXTRACTED IN TO IT'S OWN FUNCTION OR HELPER FILE. THIS CODE WILL BE NEEDED TO BUILD OTHER MODULES OR BLOCKS.
		 *
		 * Thought would be: classesDesktop.push(settingsLookup(viewtype)) or something where you can pass the viewtype in and return the class or null.
		 */
		if (data.viewtype && moduleSettingsMap[data.viewtype]) {
			classesDesktop.push(
				moduleSettingsMap[data.viewtype as keyof typeof moduleSettingsMap].additionalClass
					? moduleSettingsMap[data.viewtype as keyof typeof moduleSettingsMap].additionalClass
					: '',
			)
		}
		classesDesktop.push(
			getModuleLinkSettings(data.uaModuleLinkSettings, data.uaModuleSettings?.desktopLinkedStyles, false),
		)

		const desktopDirectionLookup = data.uaModuleSettings?.desktopDirection
		const desktopDirectionClass = desktopDirectionLookup ? 'module--right-dt' : ''
		classesDesktop.push(desktopDirectionClass)

		const textColorDefaultDesktop = getModuleLinkSettings(
			data.uaModuleLinkSettings,
			data.uaModuleSettings?.desktopLinkedStyles,
			false,
		)
			? getModuleLinkSettings(data.uaModuleLinkSettings, data.uaModuleSettings?.desktopLinkedStyles, false)
			: 'module--dark-text-dt'
		classesDesktop.push(textColorDefaultDesktop)

		const classesExplodedDesktop = classesDesktop.join(' ')
		return classesExplodedDesktop
	}

	const mobileLinkedStyle = () => {
		const classesMobile: string[] = []

		const uaMobileStyles = data.uaMobileLayoutSettings ? data.uaMobileLayoutSettings?.styleClass : ''
		classesMobile.push(uaMobileStyles)

		const mobileDirectionLookup = data.uaModuleSettings?.mobileDirection
		const mobileDirectionClass = mobileDirectionLookup ? 'module--right-mb' : ''
		classesMobile.push(mobileDirectionClass)

		const textColorDefaultMobile = getModuleLinkSettings(
			data.uaModuleLinkSettings,
			data.uaModuleSettings?.mobileLinkedStyles,
		)
			? getModuleLinkSettings(data.uaModuleLinkSettings, data.uaModuleSettings?.mobileLinkedStyles)
			: 'module--dark-text-mb'
		classesMobile.push(textColorDefaultMobile)

		const classesExplodedMobile = classesMobile.join(' ')
		return classesExplodedMobile
	}

	const getInGridStyles = () => {
		let inGridStyles = ``
		if (data?.viewtype && moduleSettingsMap[data.viewtype]) {
			const inGridSettings = moduleSettingsMap[data.viewtype]?.inGridSetting
			inGridStyles += ` ${inGridSettings}` || ` `
		}

		const inGridStylesMobile = data.uaMobileLayoutSettings?.inGridSetting
		inGridStyles += ` ${inGridStylesMobile}` || ` `

		return inGridStyles
	}

	const isPriority = position === 0

	const isTextOnly = (data?.viewtype && moduleSettingsMap?.[data?.viewtype]?.textOnly) ?? false
	const hasSeoOverflow = (data?.viewtype && moduleSettingsMap?.[data?.viewtype]?.seoSingle) ?? false

	const { width } = useWindowDimensions()

	const [isWidget, setIsWidget] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [isAriaExpanded, setIsAriaExpanded] = useState(false)

	const [wrapperHeight, setWrapperHeight] = useState(0)
	const [contentHeight, setContentHeight] = useState(0)

	const wrapperHeightRef = useRef<HTMLDivElement>(null)
	const contentHeightRef = useRef<HTMLDivElement>(null)

	const formatMessage = useFormatMessage()

	const handleOverflowViewClick = useCallback(() => {
		if (isOpen) {
			setIsOpen(false)
			setIsAriaExpanded(false)
		} else {
			setIsOpen(true)
			setIsAriaExpanded(true)
		}
	}, [isOpen])

	useEffect(() => {
		if (wrapperHeightRef.current != null && contentHeightRef.current != null) {
			const wrapperRefClientHeight = wrapperHeightRef.current.clientHeight
			const contentRefClientHeight = contentHeightRef.current.clientHeight
			if (wrapperHeight !== wrapperRefClientHeight) {
				setWrapperHeight(wrapperRefClientHeight)
			}
			if (contentHeight !== contentRefClientHeight) {
				setContentHeight(contentRefClientHeight)
			}
			if (contentHeight > wrapperHeight + 10) {
				setIsWidget(true)
			} else {
				setIsWidget(false)
			}
		}
	}, [contentHeightRef, wrapperHeightRef, contentHeight, wrapperHeight, width, isWidget])

	const textOnly = useMemo(
		() =>
			hasSeoOverflow ? (
				<div className={`module__copyarea ${styles['ingrid__seo-single']}`}>
					<div ref={contentHeightRef} className={styles['ingrid__seo-block']}>
						<Headline data={data} className="module__primary-text module__text-headline5" locale={locale} />
						<Subheadline data={data} className={`${styles['ingrid__seo-text']}`} locale={locale} />
					</div>
					<button
						type="button"
						className="showOverflow"
						onClick={handleOverflowViewClick}
						aria-expanded={!!isAriaExpanded}
					>
						{formatMessage(isOpen ? 'show-less-seo-ingrid' : 'show-more-seo-ingrid')}
					</button>
				</div>
			) : (
				<div className="module__copyarea module--twoColumn">
					<Headline data={data} className="module__primary-text module__text-headline5" locale={locale} />
					<div className="module__columns">
						<div className="module__secondary-text">
							{data.teaserTextAsTree ? <ElementTree locale={locale} element={data.teaserTextAsTree} /> : ''}
							{data.subHeadlineTextAsTree ? (
								<ElementTree
									locale={locale}
									element={data.subHeadlineTextAsTree}
									refs={data.subHeadlineTextReferencedContent}
								/>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			),
		[hasSeoOverflow, data, locale, handleOverflowViewClick, isAriaExpanded, formatMessage, isOpen],
	)

	return (
		<span
			className={clsx(styles.ingrid, getInGridStyles(), additionalClasses, {
				overflowWidget: isWidget,
				'overflowWidget--isOpen': isOpen,
			})}
		>
			<div
				className={clsx(
					'cm-module module__wrapper',
					desktopLinkedStyle(),
					mobileLinkedStyle(),
					data?.viewtype && styles[data.viewtype],
				)}
				data-testid={'ingrid-module'}
			>
				<div className="module__primary-wrapper" ref={wrapperHeightRef}>
					{isTextOnly ? (
						textOnly
					) : (
						<>
							<MediaContent data={data} priority={isPriority} placement={placement} locale={locale} />
							<Teaser locale={locale} data={data} />
						</>
					)}
				</div>
			</div>
		</span>
	)
}

export default InGrid
