import { useMemo } from 'react'
import type { HeroContentModule } from '~/lib/client-server/cms/modules/hero'
import styles from './HeroSplit.module.scss'
import { isString } from '~/lib/utils'
import ElementTree from '~/components/shared/ElementTree'
import type { Element } from '~/lib/types/coremedia.interface'
import clsx from 'clsx'
import CallsToAction from '~/components/cms/CallsToAction/CallsToAction'
import Image from '~/components/primitives/Image'

export default function HeroSplit({ data, locale, index }: { data: HeroContentModule; locale: string; index: number }) {
	const { theme, context, settings, header, subheader, images, mobile, snipeText, callsToAction } = data
	const priority = index === 0

	const hasImages = !!data.images?.[0].src || !!mobile?.images?.[0].src
	const hasContent = header || subheader || !!callsToAction?.length

	// set width on desktop only
	const cssWidth = useMemo(() => {
		if (!settings?.width || settings.width === 100) return undefined
		return {
			'--desktop-width': `${settings.width}%`,
		} as React.CSSProperties
	}, [settings?.width])

	if (!hasImages && !hasContent) return null

	const headline = settings?.titleStyle

	return (
		<section
			className={clsx(styles['hero-split'], {
				[styles['hero-split--dark']]: data.viewtype === 'hero-split-dark',
			})}
			data-theme-cms={theme}
		>
			{/* loading both mobile and desktop images, hiding them with css based on mobile/desktop media queries */}

			{hasImages && (
				<div
					className={clsx(styles['hero-split__wrapper'], {
						[styles['hero-split__wrapper--inverse']]: settings?.directionStyle,
					})}
				>
					{images?.map((img, index) => {
						return (
							<div
								key={index}
								className={clsx(styles['hero-split__img-wrapper'], {
									[styles['hero-split__img-wrapper--default']]: index === 0,
									[styles['hero-split__hide-mb']]: mobile?.image,
								})}
							>
								<Image
									src={img.src as string}
									className={styles['hero-split__img']}
									alt={img.alt}
									height={img.height}
									width={img.width}
									priority={priority}
									unoptimized
								/>
								{index === 1 && (
									<div className={styles['hero-split__callout']} style={cssWidth}>
										{snipeText && <p className={styles['hero-split__snipe-text']}>{snipeText}</p>}

										{header && (
											<div className={clsx(styles['hero-split__header'], styles[headline as string])}>
												{isString(header) ? (
													<p>{header}</p>
												) : (
													<ElementTree element={header as Element} locale={locale} />
												)}
											</div>
										)}

										{subheader && (
											<>
												{isString(subheader) ? (
													<p className={`${styles['hero-split__subheader']}`}>{subheader}</p>
												) : (
													<div className={`${styles['hero-split__subheader']}`}>
														<ElementTree element={subheader as Element} locale={locale} />
													</div>
												)}
											</>
										)}
										{!!callsToAction?.length && (
											<CallsToAction
												actions={callsToAction}
												isTextLink={settings?.isTextLinks}
												context={context}
												className={styles.herosplit__list}
											/>
										)}
									</div>
								)}
							</div>
						)
					})}
					{mobile?.images?.map((img, index) => {
						return (
							<div
								key={index}
								className={clsx(styles['hero-split__img-wrapper'], {
									[styles['hero-split__img-wrapper--default']]: index === 0,
									[styles['hero-split__hide-dt']]: images,
								})}
							>
								<Image
									src={img.src as string}
									className={styles['hero-split__img']}
									alt={img.alt}
									height={img.height}
									width={img.width}
									priority={priority}
									unoptimized
								/>
							</div>
						)
					})}
					<div
						className={clsx(styles['hero-split__callout'], {
							[styles['hero-split__hide-dt']]: images,
						})}
						style={cssWidth}
					>
						{snipeText && <p className={styles['hero-split__snipe-text']}>{snipeText}</p>}

						{header && (
							<div className={styles['hero-split__header']} data-header-cms={settings?.titleStyle}>
								{isString(header) ? <p>{header}</p> : <ElementTree element={header as Element} locale={locale} />}
							</div>
						)}

						{subheader && (
							<>
								{isString(subheader) ? (
									<p className={`${styles['hero-split__subheader']}`}>{subheader}</p>
								) : (
									<div className={`${styles['hero-split__subheader']}`}>
										<ElementTree element={subheader as Element} locale={locale} />
									</div>
								)}
							</>
						)}
						{!!callsToAction?.length && (
							<CallsToAction actions={callsToAction} isTextLink={settings?.isTextLinks} context={context} />
						)}
					</div>
				</div>
			)}
		</section>
	)
}
