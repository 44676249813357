import { useEffect } from 'react'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import type { AnalyticsContext } from '~/lib/client-server/cms/modules'
import { usePersonalizationInteractionHandlers } from '~/components/hooks/personalization/usePersonalizationInteractionHandlers'

export function useClickCTAAnalytics(context?: AnalyticsContext) {
	const { analyticsManager } = useAnalytics()

	const { handleClickThroughEvent, handleImpressionEvent } = usePersonalizationInteractionHandlers({
		context,
		source: context?.source,
	})

	useEffect(() => {
		handleImpressionEvent()
		// Trigger an update only on the `data.source` change from cm to mcp or vice verca. Do not trigger it on the whole data change.`
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context?.source])

	const handleCTAClick = (e) => {
		const ctaUrl = e.target.href
		const ctaText = e.target.innerText
		if (context?.analytics) {
			analyticsManager.trackContentModule(ctaUrl, ctaText, context?.tracking)
		} else {
			handleClickThroughEvent()
		}
	}

	return {
		handleCTAClick,
	}
}
