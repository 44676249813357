import { isCoreMediaType } from '~/lib/cms/legacy/type-guard'
import { LegacyViewTypesList, type LegacyViewTypes } from '~/lib/client-server/cms/types'
import type { LegacyContentModule } from '~/lib/client-server/cms/modules/legacy'
import { CoreMediaLegacy } from './legacy'

export default {
	identifier: (data: unknown): boolean => {
		if (isCoreMediaType(data) && data?.viewtype) {
			return LegacyViewTypesList.includes(data?.viewtype as LegacyViewTypes)
		}
		return false
	},

	transformer: (data: unknown, locale?: string): LegacyContentModule | undefined =>
		// For legacy types, we return the data as is since we
		//	are choosing not to support them in the new system.
		// CmLinkable is the top level type for all CoreMedia modules
		//	which is why we are coercing the type here.  We could run
		//	it through a Zod schema to ensure it is valid, but that
		//	would be a lot of work for little benefit because the structure
		//	is deeply nested and self-referential in ways that make it
		//	hard to represent through Zod.
		({
			type: 'legacy',
			coreMediaData: new CoreMediaLegacy(data, locale).legacyData,
		}),
}
