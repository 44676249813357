import { setup } from 'xstate'
import { type ReactNode } from 'react'
import type { AnimationType } from '~/components/cms/ContentSlot/AnimatedSlot'

/**
 * Represents the context for an animated slot.
 */
export interface AnimatedSlotContext {
	/**
	 * Indicates whether the loading placeholder is enabled for the slot.
	 */
	loadingPlaceholderEnabled: boolean

	/**
	 * Indicates whether the slot is currently in a loading state.
	 */
	isLoading: boolean

	/**
	 * The default content to be displayed in the slot.
	 */
	defaultContent?: ReactNode

	/**
	 * The personalized content to be displayed in the slot.
	 */
	personalizedContent?: ReactNode

	/**
	 * The default animation type for the slot.
	 */
	defaultAnimation?: AnimationType

	/**
	 * The personalized animation type for the slot.
	 */
	personalizedAnimation?: AnimationType
}

export const animatedSlotStateMachine = setup({
	types: {
		context: <AnimatedSlotContext>{},
		input: <AnimatedSlotContext>{},
	},
	guards: {
		// Guard to check if loadingPlaceholderEnabled is true
		isLoadingPlaceholderEnabled: ({ context }) => context.loadingPlaceholderEnabled,
		// Guard to check if loadingPlaceholderEnabled is false
		isLoadingPlaceholderDisabled: ({ context }) => !context.loadingPlaceholderEnabled,
		// Guard to check if isLoading is true
		isLoading: ({ context }) => context.isLoading,
		// Guard to check if personalizedContent is available
		hasPersonalizedContent: ({ context }) => !!context.personalizedContent,
		// Guard to check if defaultContent is available
		hasDefaultContent: ({ context }) => !!context.defaultContent,
		// Guard to check if both defaultContent and personalizedContent are available
		bothContentsAvailable: ({ context }) => !!context.defaultContent && !!context.personalizedContent,
		// Guard to check if only defaultContent is available
		onlyDefaultContent: ({ context }) => !!context.defaultContent && !context.personalizedContent,
		// Guard to check if only personalizedContent is available
		onlyPersonalizedContent: ({ context }) => !!context.personalizedContent && !context.defaultContent,
		// Guard to check if no content is available
		noContent: ({ context }) => !context.defaultContent && !context.personalizedContent,
		// Guard to check if loading is complete
		isLoadingComplete: ({ context }) => !context.isLoading,
	},
}).createMachine({
	id: 'animatedSlotMachine',

	initial: 'determiningLoadingPlaceholderEnabled',

	context: ({ input }) => ({
		...input,
	}),

	states: {
		determiningLoadingPlaceholderEnabled: {
			always: [
				// Transition to loadingPlaceholderEnabled if isLoadingPlaceholderEnabled is true
				{ target: 'loadingPlaceholderEnabled', guard: 'isLoadingPlaceholderEnabled' },
				// Otherwise, transition to checkingContentStatus if isLoadingPlaceholderDisabled is true
				{ target: 'checkingLoadingStatus', guard: 'isLoadingPlaceholderDisabled' },
			],
		},
		loadingPlaceholderEnabled: {
			initial: 'checkingLoadingStatus',
			states: {
				// State to check if loading is in progress
				checkingLoadingStatus: {
					always: [
						// Transition to showLoadingPlaceholder if isLoading is true
						{ target: 'showLoadingPlaceholder', guard: 'isLoading' },
						// Otherwise, transition to loadingComplete
						{ target: 'loadingComplete', guard: 'isLoadingComplete' },
					],
				},
				// State to show loading placeholder
				showLoadingPlaceholder: {
					always: [
						// Transition to loadingComplete if loading is done
						{ target: 'loadingComplete', guard: 'isLoadingComplete' },
					],
				},
				// State when loading is complete
				loadingComplete: {
					always: [
						// Transition to show personalized content with animation if available
						{ target: '#showPersonalizedContentWithAnimation', guard: 'hasPersonalizedContent' },
						// Transition to show default content with animation if no personalized content
						{ target: '#showDefaultContentWithAnimation', guard: 'onlyDefaultContent' },
						// If neither, transition to showing nothing
						{ target: '#showNothing', guard: 'noContent' },
					],
				},
			},
		},
		checkingLoadingStatus: {
			always: [
				// Transition to show default content while loading
				{ target: 'loadingPersonalizedContentShowingDefaultContent', guard: 'isLoading' },
				// Transition to check what to do based on content availability if loading is complete
				{ target: 'checkingContentStatus', guard: 'isLoadingComplete' },
			],
		},
		loadingPersonalizedContentShowingDefaultContent: {
			// Transition to check what to do based on content availability if loading is complete
			always: [{ target: 'checkingContentStatus', guard: 'isLoadingComplete' }],
		},
		checkingContentStatus: {
			always: [
				// Transition to show default content if only default content is available
				{ target: 'showDefaultContentNoAnimation', guard: 'onlyDefaultContent' },
				// Transition to show personalized content if only personalized content is available
				{ target: 'showPersonalizedContentNoAnimation', guard: 'onlyPersonalizedContent' },
				// Transition to show both if both are available
				{ target: 'showDefaultAndPersonalizedContentWithAnimation', guard: 'bothContentsAvailable' },
				// Transition to show nothing if neither content is available
				{ target: 'showNothing', guard: 'noContent' },
			],
		},
		// Final state to show default content without animation
		showDefaultContentNoAnimation: {
			id: 'showDefaultContentNoAnimation',
			type: 'final',
		},
		// Final state to show personalized content without animation
		showPersonalizedContentNoAnimation: {
			id: 'showPersonalizedContentNoAnimation',
			type: 'final',
		},
		// Final state for showing nothing
		showNothing: {
			id: 'showNothing',
			type: 'final',
		},
		// Final state for showing default content with animation
		showDefaultContentWithAnimation: {
			id: 'showDefaultContentWithAnimation',
			type: 'final',
		},
		// Final state for showing personalized content with animation
		showPersonalizedContentWithAnimation: {
			id: 'showPersonalizedContentWithAnimation',
			type: 'final',
		},
		// Final state for showing both default and personalized content with animation
		showDefaultAndPersonalizedContentWithAnimation: {
			id: 'showDefaultAndPersonalizedContentWithAnimation',
			type: 'final',
		},
	},
})
