'use client'

import { useMemo, useState } from 'react'
import type { CmCollection, CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import Headline from '../partials/Headline'
import Subheadline from '../partials/Subheadline'
import styles from './Accordion.module.scss'
import AccordionItem from './AccordionItem'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { getCssColor } from '~/lib/cms/legacy/helper'

interface Props {
	/** this data returns a collection - a list of items that make up the accordion, as well as the header and subheader for the accordion module */
	data: CmCollection
	/** optional placement used */
	placement?: string

	locale: string
}

const Accordion = ({ data, locale }: Props) => {
	const [openAll, setOpenAll] = useState(false)
	const handleShowAll = () => {
		setOpenAll(!openAll)
	}
	const { desktopCss, mobileCss } = useMemo(
		() => ({
			...getCssColor(data),
		}),
		[data],
	)
	const formatMessage = useFormatMessage()
	const accordionItems = data.teasableItems
	const renderAccordionItem = accordionItems?.map((item, i) => {
		return <AccordionItem key={i} item={item as CmTeasable} openAll={openAll} locale={locale} />
	})
	return (
		<section className={`${styles.accordion} ${mobileCss} ${desktopCss}`}>
			<Headline data={data} className={styles.accordion__hdr} locale={locale} />
			<Subheadline data={data} className={styles.accordion__subhdr} locale={locale} />
			<div className={styles.accordion__block}>{renderAccordionItem}</div>
			<button type="button" className={styles.accordion__openall} onClick={handleShowAll}>
				{openAll ? formatMessage('close-all-faq') : formatMessage('view-all-faq')}
			</button>
		</section>
	)
}

export default Accordion
