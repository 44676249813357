/* eslint-disable no-underscore-dangle */
import type {
	StoryPlayerPostImage,
	StoryPlayerPostModule,
	StoryPlayerPostVideo,
} from '~/lib/client-server/cms/modules/storyplayer'
import { CmMediaImpl } from './media'
import { CmTeaserImpl } from './teaser'
import logger from '~/lib/logger'

export class CmStoryPlayerPostImpl extends CmTeaserImpl {
	getMediaItem() {
		const firstMediaItem = this.getMedia('desktop')?.[0]
		if (!firstMediaItem) {
			logger.warn(`No image associated with storyplayer module - author media"${this.getModuleName()}"`)
			return undefined
		}
		return new CmMediaImpl(firstMediaItem)
	}

	getMediaType() {
		return this.getMediaItem()?.mediaType ?? 'image'
	}

	getImage() {
		return this.getMediaItem()?.toImage()
	}

	getVideo() {
		return this.getMediaItem()?.toVideo()
	}

	toPost(): StoryPlayerPostModule {
		return this.getMediaType() === 'video' ? this.toVideo() : this.toImage()
	}

	toImage(): StoryPlayerPostImage {
		return {
			type: 'storyplayer-post',
			mediaType: 'image',
			media: this.getImage(),
			title: this.getHeader(),
			description: this.getSubheader(),
			callToAction: this.getTeaserTargets().map((t) => t.toUniversal()),
		}
	}

	toVideo(): StoryPlayerPostVideo {
		return {
			type: 'storyplayer-post',
			mediaType: 'video',
			media: this.getVideo(),
			title: this.getHeader(),
			description: this.getSubheader(),
			callToAction: this.getTeaserTargets().map((t) => t.toUniversal()),
		}
	}
}
