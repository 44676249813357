/* eslint-disable  @typescript-eslint/no-explicit-any */
import { type HeroContentModule } from '~/lib/client-server/cms/modules/hero'
import { isCmImageMap, isCmTeaser, isCoreMediaType } from '~/lib/cms/legacy/type-guard'
import { logger } from '~/lib/client-server/cms/sources/coremedia/shared/module'
import { CmHeroImpl } from '~/lib/client-server/cms/sources/coremedia/shared/hero'
import { ContentSource, type HeroViewTypes, HeroViewTypesList } from '~/lib/client-server/cms/types'

export default {
	identifier: (data: unknown): boolean => {
		// if this condition is met -- the `uaModuleLinkSettings` returns undefined for the key
		if (isCoreMediaType(data) && (isCmTeaser(data) || isCmImageMap(data))) {
			return HeroViewTypesList.includes(data?.viewtype as HeroViewTypes)
		}
		return false
	},

	transformer: (data: unknown, locale?: string): HeroContentModule | undefined => {
		try {
			const hero = new CmHeroImpl(data, locale)
			const viewtype = hero.getViewtype()
			const desktopImages = hero.getImages('desktop')
			const mobileImages = hero.getImages('mobile')
			const desktopHotspots = desktopImages?.length
				? desktopImages?.[0].hotspots?.map((h) => h.toUniversal())
				: undefined
			const mobileHotspots = mobileImages?.length ? mobileImages?.[0].hotspots?.map((h) => h.toUniversal()) : undefined
			const featuredProducts = hero.getFeaturedProducts()

			return {
				type: 'hero',
				source: ContentSource.COREMEDIA,
				viewtype,
				theme: hero.getModuleTheme(),
				style: hero.moduleStyle,
				settings: {
					isCenter: hero.isTextCentered(),
					titleStyle: hero.titleStyle(),
					width: hero.getWidth(),
					isTextLinks: hero.isTextLinks(),
					posX: hero.getPosX(),
					posY: hero.getPosY(),
					directionStyle: hero.directionStyle(),
				},
				snipeText: hero.getSnipeText(),
				header: hero.getHeader(),
				subheader: hero.getSubheader(),
				image: desktopImages?.length
					? {
							id: desktopImages[0].id,
							src: desktopImages[0].src,
							alt: desktopImages[0].alt,
							height: desktopImages[0].height,
							width: desktopImages[0].width,
					  }
					: undefined,
				images: desktopImages ? desktopImages.map((t) => t.toImage()) : undefined,
				featuredProducts,
				hotspots: desktopHotspots,

				mobile: {
					image: mobileImages?.length
						? {
								id: mobileImages[0].id,
								src: mobileImages[0].src,
								alt: mobileImages[0].alt,
								height: mobileImages[0].height,
								width: mobileImages[0].width,
						  }
						: undefined,
					images: mobileImages ? mobileImages.map((t) => t.toImage()) : undefined,
					hotspots: mobileHotspots,
				},
				callsToAction: hero.getTeaserTargets().map((t) => t.toUniversal()),
				context: {
					analytics: 'handleCMClick',
					source: ContentSource.COREMEDIA,
					tracking: hero.getAnalyticsTracking(),
				},
			}
		} catch (e) {
			logger.error(e, 'Invalid input data received for rendering coremedia Hero module')
			return undefined
		}
	},
}
