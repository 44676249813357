import React, { useMemo } from 'react'
import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import CallToAction from '../partials/CallToAction'
import Headline from '../partials/Headline'
import MediaContent from '../partials/MediaContent'
import Subheadline from '../partials/Subheadline'
import styles from './HeroMini.module.scss'
import { getCssColor } from '~/lib/cms/legacy/helper'
import SnipeText from '../partials/SnipeText'

interface Props {
	// The following comments wrapped in /**  are shown in the docs for storybook
	/** The following props are passed down via Placements.tsx - data, links, placement * */
	/** We retrieve specific data from the data prop, like background color with getCssColor(), check if it is centered with getCenterPos() from the /lib/cms/coremedia/helper.ts file  * */
	data: CmTeasable
	placement?: string
	locale: string
	position: number
}

function getDesktopWidth(data) {
	const { width } = data.teaserOverlay
	return {
		width,
	}
}

function getButtonStyle(data) {
	const { mobileTeaserOverlay } = data
	const desktopTeaserOverlay = data.teaserOverlay ? data.teaserOverlay : data.teaserOverlaySettings

	const dtBtn = !!desktopTeaserOverlay?.useTextLinks
	const mbBtn = !!mobileTeaserOverlay?.useTextLinks
	return {
		dtBtn,
		mbBtn,
	}
}

function getCenterPos(data) {
	const checkPos = data.teaserOverlay.centerCopy
	return {
		centerClass: checkPos ? 'is--centered' : 'is--left',
	}
}

/** Hero Mini is a hero coremedia module that is a shortened version of our current banners. The user can select dark/light text, as well as center the entire text section - title, subtitle, and cta. The default text position is left aligned */
const HeroMini = ({ data, locale, position }: Props) => {
	const { desktopCss, mobileCss, centerClass, width, dtBtn } = useMemo(
		() => ({
			// getting background color
			...getCssColor(data),
			// checking if centered checkbox in CM
			...getCenterPos(data.uaModuleSettings),
			// get desktop width
			...getDesktopWidth(data.uaModuleSettings),
			// get button style
			...getButtonStyle(data.uaModuleSettings),
		}),
		[data],
	)

	// set width on desktop only
	const cssWidth = useMemo(
		() =>
			({
				'--desktop-width': `${width}%`,
			} as React.CSSProperties),
		[width],
	)

	return (
		<section className={`${styles.heromini} heromini`}>
			<div className={styles.heromini__img}>
				<MediaContent locale={locale} data={data} className={'module__secondary-img'} priority={position === 0} />
			</div>
			<div className={`${styles.heromini__copy} ${mobileCss} ${desktopCss} ${centerClass}`} style={cssWidth}>
				{data.snipeText && <SnipeText data={data.snipeText} />}
				<Headline data={data} locale={locale} />
				<Subheadline data={data} locale={locale} />
				<CallToAction
					locale={locale}
					parent={data}
					teaserTargets={data.teaserTargets}
					className={styles.heromini__btn}
					desktopTextLinks={dtBtn}
					mobileTextLinks={dtBtn}
				/>
			</div>
		</section>
	)
}

export default HeroMini
