import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import type { Optional } from '~/types/strict-null-helpers'
import { ensureString } from '~/types/strict-null-helpers'
import CMLink from './CMLink'
import Headline from './Headline'
import MediaItem from './MediaItem'

interface Props {
	data: CmTeasable
	imageRecipeDesktop?: Optional<string>
	imageRecipeMobile?: Optional<string>
	placement?: string
	module?: string
	locale: string
}

const CarouselItem = ({ data, locale, imageRecipeMobile, imageRecipeDesktop, placement, module }: Props) => {
	return (
		<>
			<MediaItem
				locale={locale}
				data={data}
				placement={ensureString(placement)}
				module={ensureString(module)}
				imageRecipeDesktop={imageRecipeDesktop}
				imageRecipeMobile={imageRecipeMobile}
			/>

			<div className="module__list-item-content">
				<div className="module__link-text">
					<div className="module__ctas-wrapper ctaBlock--odd">
						<CMLink
							locale={locale}
							link={data.teaserTargets?.[0]}
							parent={data}
							placement={ensureString(placement)}
							module={ensureString(module)}
						/>
					</div>
				</div>

				<Headline locale={locale} data={data} className="module__list-item-text" />
			</div>
		</>
	)
}

export default CarouselItem
