'use client'

import React, { useMemo } from 'react'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import type { ContentSlide as ContentSlideType } from '~/lib/client-server/cms/modules/content-carousel'
import coremediaIcons from 'public/cm-icons-storytelling.svg?url'

import ConditionalWrapper from '~/components/shared/ConditionalWrapper'
import ElementTree from '~/components/shared/ElementTree'
import type { Element } from '~/lib/types/coremedia.interface'
import Image from '~/components/primitives/Image'
import { bestGuessImageDimensions, getValidImageRatioForCarousel } from '~/lib/image'
import { isString } from '~/lib/utils'
import { ensureString } from '~/types/strict-null-helpers'
import styles from './ContentSlide.module.scss'
import type { ContentCarouselClickTarget } from '~/lib/types/analytics.interface'
import { useAnalytics } from '~/components/hooks/useAnalytics'

export interface CarouselSlideProps {
	/** Optional link to direct user to a landing page */
	callsToAction?: ContentSlideType['callsToAction']

	/** Optional secondary text of the module */
	description?: ContentSlideType['description']

	/** Optional branding icon rendered above the title */
	icon?: ContentSlideType['icon']

	/** Optional image to fill image container. Only gets rendered if isNumbered = false */
	image?: ContentSlideType['image']

	/** The index position of the module. This is used to send a position to the images to determine priority loading */
	index: number

	/** Optional boolean when set to true, dictates that in place of an image, a circular element with a number will be rendered */
	isNumbered?: ContentSlideType['isNumbered']

	/** User's browser locale, used for ElementTree component */
	locale: string

	/** The slide layout drives the flex direction of the slides main components (image container : content container) on mobile.
	 * Value: Column = Vertical alignment aka Image on top / Content on bottom <-- Default layout
	 * Value: Row = Horizontal alignment aka Image is left / Content is right
	 */
	layout?: ContentSlideType['layout']

	/** Image load priority */
	priority?: boolean

	/** Optional primary text of the module */
	title?: ContentSlideType['title']

	/** Pass module info down for analytics */
	module?: {
		moduleName?: string
		moduleId?: string
	}
}

interface AnalyticsClickFunction {
	(clickTarget: ContentCarouselClickTarget): void
}

interface SlideImageProps {
	image?: ContentSlideType['image']
	index: number
	isNumbered?: boolean
	priority?: boolean
	ctaLink?: string
	fireAnalyticsClick: AnalyticsClickFunction
}

function SlideImage({ image, index, isNumbered, priority, ctaLink, fireAnalyticsClick }: SlideImageProps) {
	const imageSizing = useMemo(() => {
		if (!image?.id || isNumbered) return undefined

		// Returns the cleanest version of an image ratio, but could be undefined if the imageId is malformed, which is why we use bestGuessImageDimensions as a fallback.
		const { heightRatio, widthRatio, wid } = getValidImageRatioForCarousel(image.id)

		let height = heightRatio
		let width = widthRatio

		if (!heightRatio && !widthRatio) {
			const imageDimensions = bestGuessImageDimensions(image.id, false)
			height = imageDimensions.height
			width = imageDimensions.width
		}

		return { height, width, wid }
	}, [image, isNumbered])

	// If we are using numbered slides, we create the number to be displayed dynamically based on the index and then adding 1 to account for a zero based array index
	const displayNumber = index + 1

	// TODO: The image recipes need to be updated soon. This is not the most ideal way to handle this logic at the moment, but the case for rendering the image is to only use the wid= url param. Remove this when module maps are updated
	const scene7BaseUrl = `https://underarmour.scene7.com`
	const imageSrc = !isNumbered && `${scene7BaseUrl}/is/image/Underarmour/${image?.id}?qlt=85&wid=${imageSizing?.wid}`
	const displayImage = imageSrc && image?.alt && imageSizing && !isNumbered
	// Return early if there is nothing to render
	if (!image?.src && !isNumbered) return null

	return (
		<div className={styles.slide__asset} onClick={() => fireAnalyticsClick('image')}>
			<ConditionalWrapper condition={!!ctaLink} as={LocaleLink} href={ensureString(ctaLink)}>
				{displayImage ? (
					<Image
						src={imageSrc}
						alt={image.alt}
						layout="responsive"
						width={imageSizing?.width}
						height={imageSizing?.height}
						priority={priority}
						fetchPriority={priority ? 'high' : 'low'}
					/>
				) : (
					<div className={styles.slide__asset__numbered}>{displayNumber}</div>
				)}
			</ConditionalWrapper>
		</div>
	)
}

interface SlideIconProps {
	icon?: ContentSlideType['icon']
	ctaLink?: string
	fireAnalyticsClick: AnalyticsClickFunction
}

function SlideIcon({ icon, ctaLink, fireAnalyticsClick }: SlideIconProps) {
	if (!icon) return null

	// TODO: move all icons into Functional Components per docs
	const iconTitle = `${icon}_icon`
	const linkHref = `${coremediaIcons}#${icon}`
	return (
		<ConditionalWrapper
			condition={!!ctaLink}
			as={LocaleLink}
			href={ensureString(ctaLink)}
			onClick={() => fireAnalyticsClick('icon')}
		>
			<div className={styles.slide__icon}>
				<svg xmlns="http://www.w3.org/2000/svg" role="img" aria-label={icon}>
					<title>{iconTitle}</title>
					{/* Check if coremediaIcons and icon are provided */}
					{coremediaIcons && <use xlinkHref={linkHref} />}
				</svg>
			</div>
		</ConditionalWrapper>
	)
}

interface SlideHeadlineProps {
	title: ContentSlideType['title']
	locale: string
	ctaLink?: string
	fireAnalyticsClick: AnalyticsClickFunction
}

function SlideHeadline({ title, locale, ctaLink, fireAnalyticsClick }: SlideHeadlineProps) {
	if (!title) return null

	return (
		<ConditionalWrapper
			condition={!!ctaLink}
			as={LocaleLink}
			href={ensureString(ctaLink)}
			onClick={() => fireAnalyticsClick('text')}
		>
			<div className={styles.slide__headline}>
				{isString(title) ? <p>{title}</p> : <ElementTree element={title as Element} locale={locale} />}
			</div>
		</ConditionalWrapper>
	)
}

interface SlideDescriptionProps {
	description?: ContentSlideType['description']
	locale: string
	ctaLink?: string
	fireAnalyticsClick: AnalyticsClickFunction
}

function SlideDescription({ description, locale, ctaLink, fireAnalyticsClick }: SlideDescriptionProps) {
	if (!description) return null

	return (
		<ConditionalWrapper
			condition={!!ctaLink}
			as={LocaleLink}
			href={ensureString(ctaLink)}
			onClick={() => fireAnalyticsClick('text')}
		>
			<div className={styles.slide__description}>
				{isString(description) ? (
					<p>{description}</p>
				) : (
					<ElementTree element={description as Element} locale={locale} />
				)}
			</div>
		</ConditionalWrapper>
	)
}

interface SlideCTAProps {
	callsToAction?: ContentSlideType['callsToAction']
	fireAnalyticsClick: AnalyticsClickFunction
}

function SlideCTA({ callsToAction, fireAnalyticsClick }: SlideCTAProps) {
	const renderCTA = !!callsToAction && !!callsToAction?.length

	if (!renderCTA) return null

	// Conditional rendering of SlideCTA component
	return (
		<ul className={styles.slide__cta} onClick={() => fireAnalyticsClick('text')}>
			{callsToAction?.map((link, index) => (
				<li key={index}>
					<LocaleLink data-testid={`cta_${link.url}`} href={link.url}>
						{link.text}
					</LocaleLink>
				</li>
			))}
		</ul>
	)
}

function ContentSlide({
	callsToAction,
	description,
	icon,
	image,
	index,
	isNumbered = false,
	layout,
	locale,
	priority,
	title,
	module,
}: CarouselSlideProps) {
	const { analyticsManager } = useAnalytics()
	const renderImageContainer = image?.src || isNumbered
	const renderTextContainer = !!title || !!description
	const ctaLink = callsToAction?.[0]?.url

	const fireAnalyticsClick = (clickTarget: ContentCarouselClickTarget) => () => {
		if (clickTarget)
			analyticsManager.trackContentCarouselClick({
				module: 'Content Carousel',
				clickTarget,
				imageSrc: isNumbered ? 'isNumberedSlide' : image?.src,
				moduleName: module?.moduleName,
				moduleId: module?.moduleId,
			})
	}

	return (
		<div className={styles.carousel__slide} data-mobile-layout={layout}>
			{renderImageContainer && (
				<SlideImage
					image={image}
					index={index}
					isNumbered={isNumbered}
					priority={priority}
					ctaLink={ctaLink}
					fireAnalyticsClick={fireAnalyticsClick('image')}
				/>
			)}
			<div className={styles.slide__details}>
				<SlideIcon icon={icon} ctaLink={ctaLink} fireAnalyticsClick={fireAnalyticsClick('icon')} />
				{renderTextContainer && (
					<div className={styles.slide__details__dynamic_text}>
						<SlideHeadline
							title={title}
							locale={locale}
							ctaLink={ctaLink}
							fireAnalyticsClick={fireAnalyticsClick('text')}
						/>
						<SlideDescription
							description={description}
							locale={locale}
							ctaLink={ctaLink}
							fireAnalyticsClick={fireAnalyticsClick('text')}
						/>
					</div>
				)}
				<SlideCTA callsToAction={callsToAction} fireAnalyticsClick={fireAnalyticsClick('text')} />
			</div>
		</div>
	)
}

export default ContentSlide
