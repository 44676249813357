import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

function ExpandViewIcon(props: IconProps) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...getEnhancedIconProps(props)}>
			<path
				d="M1.41016 9.83984C0.789062 9.83984 0.332031 9.35938 0.332031 8.75V2.24609C0.332031 1.05078 1.02344 0.359375 2.21875 0.359375H8.72266C9.35547 0.359375 9.82422 0.804688 9.82422 1.4375C9.82422 2.07031 9.34375 2.50391 8.72266 2.50391H7.75L3.64844 2.26953L6.89453 5.35156L10.5156 8.96094C10.7266 9.17188 10.832 9.45312 10.832 9.76953C10.832 10.4258 10.3398 10.8594 9.67188 10.8594C9.39062 10.8594 9.13281 10.7656 8.93359 10.5547L5.32422 6.92188L2.24219 3.67578L2.47656 7.76562V8.75C2.47656 9.37109 2.03125 9.83984 1.41016 9.83984ZM14.2656 22.7188C13.6445 22.7188 13.1641 22.2734 13.1641 21.6406C13.1641 21.0078 13.6445 20.5742 14.2656 20.5742H15.25L19.3516 20.8086L16.0938 17.7266L12.4844 14.1172C12.2734 13.9062 12.1562 13.625 12.1562 13.3086C12.1562 12.6523 12.6602 12.2188 13.3164 12.2188C13.5977 12.2188 13.8672 12.3125 14.0664 12.5234L17.6641 16.1562L20.7578 19.4023L20.5117 15.3125V14.3281C20.5117 13.707 20.9688 13.2383 21.5781 13.2383C22.2109 13.2383 22.668 13.7188 22.668 14.3281V20.832C22.668 22.0273 21.9648 22.7188 20.7695 22.7188H14.2656Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default ExpandViewIcon
