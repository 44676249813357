import { UaSkeleton } from '~/components/primitives/UaSkeleton/UaSkeleton'

function SkeletonSlide() {
	return (
		<>
			<UaSkeleton variant="rectangular" height={324} />
			<UaSkeleton variant="rectangular" height={25} />
			<UaSkeleton variant="rectangular" height={15} />
		</>
	)
}

export default SkeletonSlide
