'use client'

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-unresolved */
import 'swiper/css'
import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import { Keyboard, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import CMLink from '~/components/cms/legacy/partials/CMLink'
import Headline from '~/components/cms/legacy/partials/Headline'
import Icon from '~/components/cms/legacy/partials/Icon'
import MediaContent from '~/components/cms/legacy/partials/MediaContent'
import MediaItem from '~/components/cms/legacy/partials/MediaItem'
import Subheadline from '~/components/cms/legacy/partials/Subheadline'
import type { CmCollection, CmTeasable, Maybe } from '~/graphql/generated/coremedia/type-document-node'
import { isCmTeaser, isLinkedSetting } from '~/lib/cms/legacy/type-guard'
import type { ClientProductTile } from '~/lib/types/product.interface'
import { ensureString } from '~/types/strict-null-helpers'
import { useProductsFromCollection } from '~/components/hooks/cms/legacy/useProductsFromCollection'

import styles from './Storytelling.module.scss'
import { ProductTile } from '~/components/shared/ProductTile/ProductTile'

interface Props {
	data: CmCollection
	placement?: string
	locale: string
}

const Storytelling = ({ data, placement, locale }: Props) => {
	const { carouselItems } = useProductsFromCollection(data?.teasableItems)

	const sliderType = useMemo(() => {
		const hasTeasers = carouselItems?.some((item) => isCmTeaser(item))
		return hasTeasers ? 'CarouselItem' : 'ProductTile'
	}, [carouselItems])

	const getStyle = useCallback(
		(resolutionType: 'mobile' | 'desktop') => {
			const desktopLinkedStyle =
				data.uaModuleLinkSettings &&
				data.uaModuleLinkSettings.find(
					(obj) =>
						obj &&
						obj.key ===
							(resolutionType === 'mobile'
								? data.uaModuleSettings?.mobileLinkedStyles
								: data.uaModuleSettings?.desktopLinkedStyles),
				)
			const setting =
				desktopLinkedStyle &&
				desktopLinkedStyle.value &&
				desktopLinkedStyle.value.find((obj) => obj && isLinkedSetting(obj) && obj?.description)
			return setting && isLinkedSetting(setting) ? `${setting.description}` : ''
		},
		[data],
	)

	const mobileStyle = useMemo(() => getStyle('mobile'), [getStyle])
	const desktopStyle = useMemo(() => getStyle('desktop'), [getStyle])

	const wrapperClasses = useMemo(
		() =>
			clsx('cm-module module__wrapper', data?.viewtype && styles[data.viewtype], {
				[styles['dark-mb']]: mobileStyle === 'light-text',
				[styles['dark-dt']]: desktopStyle === 'light-text',
			}),
		[data.viewtype, mobileStyle, desktopStyle],
	)

	return (
		<div className={wrapperClasses}>
			<div className={styles['content-wrapper']}>
				<MediaContent
					locale={locale}
					data={data}
					imageRecipeDesktop="recipe-collection-logo"
					imageRecipeMobile="recipe-collection-logo-sm"
					className={styles['content-logo-wrapper']}
					placement={ensureString(placement)}
					module={ensureString(data.viewtype)}
				/>
				<Headline data={data} className={styles.headline} locale={locale} />
				<Subheadline data={data} className={styles.subheadline} locale={locale} />
			</div>

			<Swiper
				className={styles['slider-wrapper']}
				modules={[Navigation, Keyboard]}
				slidesPerView={1.1}
				spaceBetween={8}
				slidesOffsetAfter={16}
				breakpoints={{
					768: {
						slidesPerView: 3,
						spaceBetween: 16,
						enabled: false, // kills slide functionality
					},
					1023: {
						slidesPerView: 3,
						spaceBetween: 28, // possibly need some sort of logic here to handle. the column gap css is not applying from the core media files
						enabled: false, // kills slide functionality
					},
				}}
				keyboard={{ enabled: true }}
				scrollbar={{ draggable: true }}
			>
				{carouselItems && sliderType === 'ProductTile'
					? (carouselItems as ClientProductTile[]).map((item, index) => (
							<SwiperSlide key={index} className={styles.slide}>
								<ProductTile key={index} product={item} showColorInfo={false} wishListEnabled={false} inSwiper={true} />
							</SwiperSlide>
					  ))
					: (carouselItems as Maybe<CmTeasable>[])
							?.filter((teaser) => teaser && isCmTeaser(teaser))
							.map(
								(teaser, index) =>
									teaser && (
										<SwiperSlide key={index} className={styles.slide}>
											<div className={styles['slide-content']}>
												<div className={styles['slide-image']}>
													<MediaItem
														locale={locale}
														data={teaser}
														placement={ensureString(placement)}
														module={ensureString(data.viewtype)}
														imageRecipeDesktop={'recipe-bucket-3up'}
														imageRecipeMobile={'recipe-bucket-3up'}
													/>
												</div>

												<Icon data={teaser} locale={locale} />
												<Headline data={teaser} className={styles['slide-headline']} locale={locale} />
												<Subheadline data={teaser} className={styles['slide-subheadline']} locale={locale} />
												<CMLink
													locale={locale}
													parent={teaser}
													link={teaser?.teaserTargets?.[0]}
													placement={ensureString(placement)}
													module={ensureString(data.viewtype)}
												/>
											</div>
										</SwiperSlide>
									),
							)}
			</Swiper>
		</div>
	)
}

export default Storytelling
