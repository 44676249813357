import { useState } from 'react'
import CaretIcon from '~/components/primitives/icons/CaretIcon'
import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import type { IconColor } from '~/lib/icons'
import variables from '~/styles/variables.module.scss'
import Article from '../Article/Article'
import styles from './Accordion.module.scss'
import clsx from 'clsx'

interface Props {
	/** this data has the accordion button copy and body text */
	item: CmTeasable
	/** close all/show all state */
	openAll: boolean

	locale: string
}

const AccordionItem = ({ item, openAll, locale }: Props) => {
	const [isActive, setActive] = useState(openAll)
	const handleAccordionToggle = () => {
		setActive(!isActive)
	}

	return (
		<div className={styles.accordion__item}>
			<button
				type="button"
				className={styles.accordion__btn}
				aria-expanded={isActive}
				aria-controls={`item-${item?.id}`}
				onClick={handleAccordionToggle}
			>
				{item?.title}
				<span className={styles.accordion__icon} aria-hidden="true" tabIndex={-1}>
					<CaretIcon
						size="SM"
						color={variables.black as IconColor}
						className={clsx({
							[styles['accordion__icon--open']]: isActive,
							[styles['accordion__icon--closed']]: !isActive,
						})}
					/>
				</span>
			</button>
			<div className={isActive || openAll ? styles.accordion__show : styles.accordion__hide}>
				<Article data={item} locale={locale} className={styles.accordion__content} />
			</div>
		</div>
	)
}

export default AccordionItem
