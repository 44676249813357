import type { HeroContentModule } from '~/lib/client-server/cms/modules/hero'
import styles from './HeroCLP.module.scss'
import { isString } from '~/lib/utils'
import ElementTree from '~/components/shared/ElementTree'
import type { Element } from '~/lib/types/coremedia.interface'
import clsx from 'clsx'
import CmsImage from '../CmsImage/CmsImage'
import CallsToAction from '~/components/cms/CallsToAction/CallsToAction'

export default function HeroCLP({ data, locale, index }: { data: HeroContentModule; locale: string; index: number }) {
	const { theme, context, header, image, mobile, settings, callsToAction } = data
	const priority = index === 0
	const hasImages = !!image?.src || !!mobile?.image?.src
	const hasContent = header || !!callsToAction?.length
	if (!hasImages && !hasContent) return null
	const headline = settings?.titleStyle

	return (
		<section className={styles.herocat} data-viewtype={data.viewtype}>
			{hasImages && (
				<div className={styles['herocat__img-wrapper']}>
					{image?.src && (
						<CmsImage image={image} ctaLink={image?.ctaLink} hotspots={data.hotspots} priority={priority} />
					)}
					{mobile?.image?.src && (
						<CmsImage
							context={context}
							ctaLink={image?.ctaLink}
							image={mobile?.image}
							hotspots={mobile?.hotspots}
							priority={priority}
							isMobile
						/>
					)}
				</div>
			)}

			<div className={styles.herocat__callout} data-theme-cms={theme}>
				<div className={clsx(styles.herocat__header, styles[headline as string])}>
					{isString(header) ? <p>{header}</p> : <ElementTree element={header as Element} locale={locale} />}
				</div>

				{!!callsToAction?.length && (
					<CallsToAction
						actions={callsToAction}
						isTextLink={settings?.isTextLinks}
						context={context}
						className={styles.herocat__list}
					/>
				)}
			</div>
		</section>
	)
}
