/* eslint-disable no-underscore-dangle */
import { isLinkedSetting } from '~/lib/cms/legacy/type-guard'
import { CmTeaserImpl } from './teaser'
import { CmMediaImpl } from './media'
import type { DeviceType } from './module'
import logger from '~/lib/logger'
import type { AnalyticsTracking } from '~/lib/client-server/cms/modules'

export class CmContentSlide extends CmTeaserImpl {
	getImage(deviceType: DeviceType): CmMediaImpl | undefined {
		const image = this.getMedia(deviceType)?.[0]
		if (!image) {
			logger.warn(`No image associated with content slide module "${this.getModuleName()}"`)
			return undefined
		}
		return new CmMediaImpl(image)
	}

	get icon() {
		// TODO: clean up how we look for icons. Currently CoreMedia returns a string that maps to the public/cm-icons-storytelling.svg
		const settingLookupKey = this.getUaModuleSettings()?.linkedIcon
		const setting = settingLookupKey && this.raw.uaModuleLinkSettings.find((obj) => obj && obj.key === settingLookupKey)
		if (!setting || !setting.value) return undefined

		const classCheck = setting.value.find((obj) => obj && isLinkedSetting(obj) && obj?.description)
		return classCheck && isLinkedSetting(classCheck) && classCheck.description ? classCheck.description : undefined
	}

	get analyticsTracking(): AnalyticsTracking {
		const desktopImage = this.getImage('desktop')
		return {
			header: this.getHeader(),
			asset_name: desktopImage
				? {
						id: desktopImage.id,
						src: desktopImage.src,
						alt: desktopImage.alt,
						height: desktopImage.height,
						width: desktopImage.width,
				  }
				: undefined,
			module: 'content',
			placement: 'content',
			snipe: this.getSnipeText(),
		}
	}
}
