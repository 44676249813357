import HeroOverlay from '~/components/cms/HeroOverlay/HeroOverlay'
import HeroMini from '~/components/cms/HeroMini/HeroMini'
import { HeroStyle, type HeroContentModule } from '~/lib/client-server/cms/modules/hero'
import HeroCLP from '../HeroCLP/HeroCLP'
import HeroSplit from '../HeroSplit/HeroSplit'

// following the naming convention of ContentSlot.tsx.
// checking to see what hero style is selected within mcp or coremedia and load appropriate component
export default function HeroSlot({ data, locale, index }: { data: HeroContentModule; locale: string; index: number }) {
	switch (data.style) {
		case HeroStyle.Overlay:
			return <HeroOverlay data={data} locale={locale} index={index} />
		case HeroStyle.Mini:
			return <HeroMini data={data} locale={locale} index={index} />
		case HeroStyle.CategoryHero:
			return <HeroCLP data={data} locale={locale} index={index} />
		case HeroStyle.Split:
			return <HeroSplit data={data} locale={locale} index={index} />
		default:
			return null
	}
}
