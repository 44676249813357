/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-unresolved */
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/navigation'

import { useState } from 'react'
import { Grid, Keyboard, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

import CarouselItem from '~/components/cms/legacy/partials/CarouselItem'
import type { CmTeasable, Maybe } from '~/graphql/generated/coremedia/type-document-node'
import type { ClientProductTile } from '~/lib/types/product.interface'

import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import styles from './Slider.module.scss'
import { ProductTile } from '~/components/shared/ProductTile/ProductTile'

interface Props {
	slides: Maybe<Maybe<CmTeasable>[]> | ClientProductTile[] | undefined
	columnCountMobile: number
	columnCountDesktop: number
	sliderType?: string
	imageRecipeMobile?: string[]
	imageRecipeDesktop?: string[]
	isCarouselMobile: boolean
	placement: string
	module: string
	cmAdvancedProductTilesEnabled: boolean
	locale: string
}

const SliderNavigation = ({ slot }: { slot: 'container-end' }) => {
	const formatMessage = useFormatMessage()
	const swiper = useSwiper()
	const [slideProgress, setSlideProgress] = useState<number>(0)
	swiper.on('slideChange', (e) => {
		setSlideProgress(e.progress)
	})

	return (
		<span className={`${styles.slider__nav_wrap}`} slot={slot}>
			<button
				type="button"
				className={`prev ${styles.slider_button} ${styles.prev}`}
				onClick={() => swiper.slidePrev()}
				disabled={slideProgress === 0}
			>
				{formatMessage('cms-slider-prev-slide')}
			</button>
			<button
				type="button"
				className={`next ${styles.slider_button} ${styles.next}`}
				onClick={() => swiper.slideNext()}
				disabled={slideProgress === 1}
			>
				{formatMessage('cms-slider-next-slide')}
			</button>
		</span>
	)
}

const Slider = ({
	slides,
	columnCountMobile,
	columnCountDesktop,
	sliderType = 'CarouselItem',
	imageRecipeMobile,
	imageRecipeDesktop,
	isCarouselMobile,
	placement,
	module,
	cmAdvancedProductTilesEnabled,
	locale,
}: Props) => {
	/**
	 * Set slider image recipes, these should come from the bucket when being built,
	 * or the slider when implemented, if not, set some default image recipes
	 */
	const recipeDesktop = imageRecipeDesktop?.[0]
	const recipeMobile = imageRecipeMobile?.[0] || 'recipe-bucket-square'
	const moduleWrapperClass = isCarouselMobile ? `module__swiper-carousel` : `module__swiper-wrap`
	return (
		<div className={`module__list ${moduleWrapperClass} ${styles[moduleWrapperClass]}`}>
			<Swiper
				modules={[Navigation, Keyboard, Grid]}
				slidesPerView={isCarouselMobile ? 'auto' : columnCountMobile}
				loopAdditionalSlides={1.3}
				spaceBetween={8}
				pagination={{ clickable: true }}
				breakpoints={{
					0: {
						slidesOffsetAfter: 77,
					},
					410: {
						slidesOffsetAfter: 96,
					},
					500: {
						slidesOffsetAfter: 116,
					},
					590: {
						slidesOffsetAfter: 126,
					},
					680: {
						slidesOffsetAfter: 136,
					},
					768: {
						slidesPerView: columnCountDesktop,
						spaceBetween: 20,
					},
					1023: {
						slidesPerView: columnCountDesktop,
						spaceBetween: 44, // possibly need some sort of logic here to handle. the column gap css is not applying from the core media files
					},
				}}
				navigation={{
					enabled: true,
					prevEl: '.prev',
					nextEl: '.next',
				}}
				keyboard={{ enabled: true }}
				scrollbar={{ draggable: true }}
			>
				{slides &&
					(sliderType === 'ProductTile'
						? (slides as ClientProductTile[]).map((item, index) => (
								<SwiperSlide key={index} className="module__list-item">
									<ProductTile
										key={index}
										product={item}
										showColorInfo={cmAdvancedProductTilesEnabled}
										wishListEnabled={cmAdvancedProductTilesEnabled}
										badgesEnabled={cmAdvancedProductTilesEnabled}
										promotionsEnabled={cmAdvancedProductTilesEnabled}
										inSwiper={true}
										isExperimentalView
									/>
								</SwiperSlide>
						  ))
						: (slides as Maybe<CmTeasable>[])
								.filter((teaser) => teaser && teaser?.type === 'CMTeaser')
								.map(
									(teaser, index) =>
										teaser && (
											<SwiperSlide key={index} className="module__list-item">
												<CarouselItem
													locale={locale}
													key={teaser.id}
													data={teaser}
													imageRecipeMobile={recipeMobile}
													imageRecipeDesktop={recipeDesktop}
													placement={placement}
													module={module}
												/>
											</SwiperSlide>
										),
								))}
				<SliderNavigation slot="container-end" />
			</Swiper>
		</div>
	)
}

export default Slider
