import type { ContentModule, ContentSourceTransformerRegistry } from '../../types'
import heroTransformer from './hero'
import contentCarouselTransformer from './content-carousel'
import storyplayerTransformer from './storyplayer'
import legacyTransformer from './legacy'

const registry: ContentSourceTransformerRegistry = [
	{
		type: 'hero',
		...heroTransformer,
	},
	{
		type: 'carousel',
		...contentCarouselTransformer,
	},

	{
		type: 'storyplayer',
		...storyplayerTransformer,
	},

	{
		type: 'legacy',
		...legacyTransformer,
	},

	/// /  NOTE: Add new transformers here as we define them.
]

export default function coremediaTransformer(data: unknown, locale?: string): ContentModule | undefined {
	const moduleHandler = registry.find((m) => m.identifier(data))
	if (moduleHandler) {
		return moduleHandler.transformer(data, locale)
	}

	return undefined
}
