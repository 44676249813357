import { useMemo, useEffect, useState, useCallback } from 'react'
import { useActor } from '@xstate/react'
import Placement from '~/components/cms/legacy/Placement'
import type { ContentModule, ContentSourceType } from '~/lib/client-server/cms/types'
import { transform } from '~/lib/client-server/cms/base'
import usePersonalization from '~/components/hooks/personalization/usePersonalization'
import InGridStoryPlayer from '~/components/cms/InGridStoryPlayer/InGridStoryPlayer'
import type { ContentCarouselModule } from '~/lib/client-server/cms/modules/content-carousel'
import AnimatedSlot from '~/components/cms/ContentSlot/AnimatedSlot'
import HeroSlot from '~/components/cms/HeroSlot/HeroSlot'
import ContentCarousel from '~/components/cms/ContentCarousel/ContentCarousel'
import styles from './AnimatedSlot.module.scss'
import useTimer from '~/components/hooks/useTimer'
import { contentSlotStateMachine } from './statemachines/contentSlotStateMachine'
import useNavigation from '~/components/hooks/useNavigation'
import clsx from 'clsx'

/**
 * Props for the ContentSlot component.
 */
interface Props {
	/**
	 * The unique identifier for the placement.
	 */
	placementId: string

	/**
	 * The default module to be displayed. It contains the data and the source of the content.
	 */
	defaultModule: {
		/**
		 * The data to be displayed.
		 */
		data: unknown

		/**
		 * The source of the content.
		 */
		source: ContentSourceType
	}

	/**
	 * The locale for the content.
	 */
	locale: string

	/**
	 * The index of the content slot. Controls priority preload for Next Image. Index 0 is priority.
	 */
	index: number

	/**
	 * An optional class name to pass in to the main wrapper
	 */
	className?: string

	/**
	 * Reset the timer and state machine when the page navigates. Useful if you expect this component NOT to be unmounted during SPA navigation
	 * but it will be given new props that effectively mean it should re-render.
	 */
	resetOnNavigation?: boolean
}

/**
 * Custom hook that resets a timer and sends a navigation event to an actor when the route changes.
 *
 * @param {Object} options - The options for the hook.
 * @param {Function} options.resetTimer - The function to reset the timer.
 * @param {Function} options.sendToActor - The function to send a message to the actor.
 * @param {boolean} options.resetOnNavigation - Whether to reset the timer and state machine when the page navigates.
 *
 * @example
 * // Usage example
 * function MyComponent() {
 *   const { timerState, resetTimer } = useTimer(1000, placementId === 'hero')
 *   const [actorState, sendToActor] = useActor(contentSlotStateMachine)
 *
 *   useResetOnNavigation({ resetTimer, sendToActor, resetOnNavigation: true });
 * }
 */
export function useResetOnNavigation({
	resetTimer,
	sendToActor,
	resetOnNavigation,
}: {
	resetTimer: () => void
	sendToActor: ReturnType<typeof useActor<typeof contentSlotStateMachine>>[1]
	resetOnNavigation?: boolean
}): void {
	// Reset the timer when the route changes where a re-render does not occur
	const onRouteChange = useCallback(() => {
		if (!resetOnNavigation) {
			return
		}
		sendToActor({ type: 'NAVIGATE' })
		// Will automatically restart the timer due to autoStart being true
		resetTimer()
	}, [sendToActor, resetTimer, resetOnNavigation])
	useNavigation({ onRouteChange })
}

/**
 * Custom hook to manage the height of a div element.
 * @example
 * const MyComponent = () => {
 *   const [divHeight, handleHeightUpdate, style] = useDivHeight();
 *
 *   // Example of updating the height
 *   const updateHeight = () => {
 *     handleHeightUpdate(200);
 *   };
 *
 *   return (
 *     <div style={style}>
 *       <button onClick={updateHeight}>Update Height</button>
 *     </div>
 *   );
 * };
 */
export function useDivHeight(): [(height: number) => void, React.CSSProperties] {
	const [divHeight, setDivHeight] = useState<number | undefined>(undefined)

	const handleHeightUpdate = (height: number) => {
		setDivHeight(height)
	}

	const style: React.CSSProperties = {}

	// Note! Modify the height only when the personalized content height is more than the default content height
	if (divHeight !== undefined) {
		style.height = divHeight
	}

	return [handleHeightUpdate, style]
}

/**
 * A custom hook that manages personalized content.
 * It sends events to a state machine when certain conditions are met,
 * and prepares personalized content for rendering.
 *
 * @param {object} params - The parameters for the hook.
 * @param {object} params.timerState - The state of the timer.
 * @param {function} params.sendToActor - Function to send events to the state machine.
 * @param {boolean} params.personalizedContentEnabled - Flag indicating if personalized content is enabled.
 * @param {string} params.locale - The locale.
 * @param {number} params.index - The index.
 * @returns {Array} An array containing the personalized content render and a function to handle height update along with its style.
 *
 * @example
 * function ContentSlot({
 *	 placementId,
 *	 defaultModule,
 *	 locale,
 *	 index,
 *	 className = '',
 *	 resetOnNavigation,
 * }: Props) {
 *   const personalizedContentEnabled = useMemo(() => placementId === 'hero', [placementId])
 *   const { timerState, resetTimer } = useTimer(1000, personalizedContentEnabled)
 *   const [actorState, sendToActor] = useActor(contentSlotStateMachine)
 *   const [personalizedContentRender, handleHeightUpdate, style] = usePersonalizedContent({ timerState, sendToActor, personalizedContentEnabled, locale, index });
 *   // ...
 * }
 */
export function usePersonalizedContent({
	timerState,
	sendToActor,
	personalizedContentEnabled,
	locale,
	index,
}: {
	timerState: ReturnType<typeof useTimer>['timerState']
	sendToActor: ReturnType<typeof useActor<typeof contentSlotStateMachine>>[1]
	personalizedContentEnabled: boolean
	locale: string
	index: number
}): [JSX.Element | null, (height: number) => void, React.CSSProperties] {
	// Send TIMER_FINISHED event to the state machine when the timer finishes
	// and personalized content is enabled
	useEffect(() => {
		if (timerState.status === 'finished' && personalizedContentEnabled) {
			sendToActor({ type: 'TIMER_FINISHED' })
		}
	}, [timerState, sendToActor, personalizedContentEnabled])

	const personalizationContext = usePersonalization()
	const personalizedModuleData = useMemo(() => {
		// If the placement is 'hero', we need to check if there is personalized content available
		if (personalizedContentEnabled) {
			const { placementData, source } = personalizationContext?.getPlacementData() || {}
			if (placementData?.payload && source) {
				const personalizationContent = transform(placementData.payload, source)
				if (personalizationContent) {
					return personalizationContent
				}
			}
		}

		return undefined
	}, [personalizedContentEnabled, personalizationContext])

	const personalizedContentRender: JSX.Element | null = useMemo(() => {
		if (!personalizedContentEnabled) {
			return null
		}

		let personalizedRender: JSX.Element | null = null
		switch (personalizedModuleData?.type) {
			case 'hero':
				personalizedRender = <HeroSlot data={personalizedModuleData} locale={locale} index={index} />
				break
			default:
				personalizedRender = null
		}

		return personalizedRender
	}, [personalizedModuleData, locale, index, personalizedContentEnabled])

	const [handleHeightUpdate, style] = useDivHeight()

	// Send PERSONALIZED_CONTENT_AVAILABLE event to the state machine when personalized content becomes available
	// This will be ignored if the timer has already finished
	useEffect(() => {
		if (personalizedContentRender && personalizedContentEnabled) {
			sendToActor({ type: 'PERSONALIZED_CONTENT_AVAILABLE', content: personalizedContentRender })
		}
	}, [personalizedContentEnabled, personalizedContentRender, sendToActor])

	return [personalizedContentRender, handleHeightUpdate, style]
}

/**
 * Custom hook to render default content based on module type and to determine content animation.
 *
 * @param {object} defaultModuleData - Data for the default module.
 * @param {string} locale - The locale for the content.
 * @param {number} index - The index of the module.
 * @param {string} placementId - The placement ID for the module.
 * @param {object} actorState - The state of the actor.
 * @param {boolean} personalizedContentEnabled - Flag indicating if personalized content is enabled.
 * @returns {[content: JSX.Element | null, animation: string | undefined]} Object containing the rendered content and animation type.
 *
 * @example
 * const defaultModuleData: ContentModule | undefined = useMemo(() => {
 *		if (defaultModule) {
 *			return transform(defaultModule.data, defaultModule?.source, locale)
 *		}
 *		return undefined
 *	}, [defaultModule, locale])
 * const [actorState, sendToActor] = useActor(contentSlotStateMachine)
 * const { content, animation } = useDefaultContentRender({
 *   defaultModuleData: moduleData,
 *   locale: 'en-US',
 *   index: 1,
 *   placementId: '12345',
 *   actorState: someActorState,
 *   personalizedContentEnabled: true
 * });
 */
export function useDefaultContentRender(
	defaultModuleData: ContentModule | undefined,
	locale: string,
	index: number,
	placementId: string,
	actorState: ReturnType<typeof useActor>[0],
	personalizedContentEnabled: boolean,
): [JSX.Element | null, 'fade-out' | 'fade-in' | undefined] {
	const content = useMemo(() => {
		switch (defaultModuleData?.type) {
			case 'hero':
				return <HeroSlot data={defaultModuleData} locale={locale} index={index} />
			case 'legacy': {
				const legacyData = defaultModuleData
				return (
					<div className={`${legacyData.coreMediaData.type} module-wrapper`}>
						<Placement placement={placementId} linkable={legacyData.coreMediaData} index={index} locale={locale} />
					</div>
				)
			}
			case 'carousel': {
				const carouselData = defaultModuleData as ContentCarouselModule
				return (
					<ContentCarousel
						theme={carouselData.theme}
						layout={carouselData.layout}
						maxSlidesPerRow={carouselData.maxSlidesPerRow}
						slides={carouselData.slides}
						settings={carouselData?.settings}
						title={carouselData?.title}
						description={carouselData?.description}
						callsToAction={carouselData?.callsToAction}
						mobile={carouselData?.mobile}
						moduleName={carouselData.name}
						moduleId={carouselData.id}
						index={index}
					/>
				)
			}
			case 'storyplayer': {
				const storyplayerData = defaultModuleData
				return (
					<InGridStoryPlayer
						description={storyplayerData?.description}
						isPriority={index === 0}
						locale={locale}
						media={storyplayerData?.media}
						mobile={storyplayerData.mobile}
						posts={storyplayerData.posts}
						profile={storyplayerData.profile}
						theme={storyplayerData.theme}
						title={storyplayerData?.title}
					/>
				)
			}
			default:
				return null
		}
	}, [defaultModuleData, locale, index, placementId])

	const animation = useMemo(() => {
		if (actorState.matches('showingDefault') && personalizedContentEnabled) {
			return 'fade-in'
		}

		if (actorState.matches('showingPersonalized') && personalizedContentEnabled) {
			return 'fade-out'
		}

		return undefined
	}, [actorState, personalizedContentEnabled])

	return [content, animation]
}

/**
 * `ContentSlot` is a React component that is responsible for rendering different types of content modules based on the provided props.
 *
 * @component
 * @param {Object} props The properties object.
 * @param {string} props.placementId The ID of the placement where the content module will be rendered.
 * @param {Object} props.defaultModule The default content module to be rendered. It has two properties: `data` and `source`.
 * @param {ContentModule} props.defaultModule.data The data of the default content module.
 * @param {ContentSourceType} props.defaultModule.source The source of the default content module.
 * @param {string} props.locale The locale in which the content module will be rendered.
 * @param {number} props.index The index of the content module. Controls priority preload for Next Image. Index 0 is priority.
 * @param {string} props.className The optional class name that can be added to more granular styling.
 * @param {boolean} props.resetOnNavigation Whether to reset the timer and state machine when the page navigates. Useful if you expect this
 * 											component NOT to be unmounted during SPA navigation but it will be given new props that effectively
 * 											mean it should re-render.
 *
 * @returns {JSX.Element|null} Returns a JSX element if there is a content module to be rendered, otherwise returns null.
 */
export default function ContentSlot({
	placementId,
	defaultModule,
	locale,
	index,
	className = '',
	resetOnNavigation,
}: Props) {
	const defaultModuleData: ContentModule | undefined = useMemo(() => {
		if (defaultModule) {
			return transform(defaultModule.data, defaultModule.source, locale)
		}
		return undefined
	}, [defaultModule, locale])
	const personalizedContentEnabled = useMemo(() => placementId === 'hero', [placementId])
	const { timerState, resetTimer } = useTimer(1000, personalizedContentEnabled)
	const [actorState, sendToActor] = useActor(contentSlotStateMachine)
	useResetOnNavigation({ resetTimer, sendToActor, resetOnNavigation })

	const [personalizedContentRender, handleHeightUpdate, style] = usePersonalizedContent({
		timerState,
		sendToActor,
		personalizedContentEnabled,
		locale,
		index,
	})

	const [defaultContentRender, defaultContentAnimation] = useDefaultContentRender(
		defaultModuleData,
		locale,
		index,
		placementId,
		actorState,
		personalizedContentEnabled,
	)

	// If no content exists due to unsupported types, return null
	if (!defaultContentRender && !personalizedContentRender) {
		return null
	}

	// NOTE: In order to prevent re-renders and a flash of disappearing content due to HTML structural changes,
	// always use an animated slot with wrapper div
	return (
		<div data-testid="animated-slot-must-always-exist" className={clsx(styles.animated_slot, className)} style={style}>
			<AnimatedSlot
				// If personalized content is enabled and the actor is in the loading state, show the loading placeholder
				isLoading={personalizedContentEnabled && actorState.matches('loading')}
				// If personalized content is enabled, enable the loading placeholder (grey box placeholder)
				loadingPlaceholderEnabled={personalizedContentEnabled}
				defaultContent={defaultContentRender}
				// If personalized content is enabled and the actor is in the showingPersonalized state, show the personalized content
				personalizedContent={
					personalizedContentEnabled && actorState.matches('showingPersonalized') && personalizedContentRender
				}
				// If default AND personalized content is available, fade out the default content.
				defaultAnimation={defaultContentAnimation}
				// If default AND personalized content is available, fade in the personalized content.
				personalizedAnimation="fade-in"
				// Set the height of the div to the height of the content
				handleHeightUpdate={handleHeightUpdate}
			/>
		</div>
	)
}
