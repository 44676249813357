import { ContentSource, type ContentModule } from './types'
import coreMediaTransformer from './sources/coremedia'
import mcpTransformer from './sources/mcp'

/**
 * This is the main transformer function.  It takes the data and the source
 * as arguments and returns a ContentModule object or undefined if the
 * data is invalid.  Note that the ContentModule object includes a 'type'
 * This type is meant to be used by the caller to determine which component
 * to use to render the content.
 *
 * Example code:
 *
 * ```
 * const cmData = fetch('....coremedia...')
 * const normalizedModuleData = transform(cmData, 'coremedia')
 * switch (normalizedModuleData.type) {
 *    case 'hero':
 *       return <HeroComponent data={normalizedModuleData} />
 *    ...
 * }
 * ```
 */
export function transform(data: unknown, source: ContentSource, locale?: string): ContentModule | undefined {
	if (source === ContentSource.COREMEDIA) {
		return coreMediaTransformer(data, locale)
	}

	if (source === ContentSource.MCP) {
		return mcpTransformer(data)
	}

	return undefined
}
