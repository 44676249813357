import { isString } from '~/lib/utils'

import ElementTree from '~/components/shared/ElementTree'
import type { Element } from '~/lib/types/coremedia.interface'
import ConditionalWrapper from '~/components/shared/ConditionalWrapper'
import { ensureString } from '~/types/strict-null-helpers'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import type { CmTextTreeSafe } from '~/lib/client-server/cms/sources/coremedia/shared/schemas'

export interface TextProps {
	text: string | CmTextTreeSafe
	locale: string
	className?: string
	link?: string
}

export default function CmsText({ text, locale, link, className = '' }: TextProps) {
	if (!text) return null

	return (
		<div className={className}>
			<ConditionalWrapper condition={!!link} as={LocaleLink} href={ensureString(link)}>
				{isString(text) ? <p>{text}</p> : <ElementTree element={text as Element} locale={locale} />}
			</ConditionalWrapper>
		</div>
	)
}
