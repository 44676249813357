/* eslint-disable no-underscore-dangle */
import { CmTeaserImpl } from './teaser'
import { CmCollectionSchema, type CmCollectionSafe } from './schemas'
import type {
	ContentCarouselLayout,
	ContentCarouselModule,
	ContentCarouselStyle,
	RenderableCarouselSlide,
} from '~/lib/client-server/cms/modules/content-carousel'
import { isCmArticle, isCmUaBucket, isLinkedViewType } from '~/lib/cms/legacy/type-guard'
import { CmSlideImpl } from './slide'
import type { PageContentData } from '~/lib/types/coremedia.interface'
import { getListOfItemsInPlacement } from '~/lib/content'
import type { CmLinkable } from '~/graphql/generated/coremedia/type-document-node'
import { ensureNonNullishArray } from '~/types/strict-null-helpers'
import { getProductFromProductSlide } from '~/lib/client-server/cms/sources/coremedia/shared/content-product'

export class CmCollectionImpl extends CmTeaserImpl {
	private _parsedTeasableItems: CmCollectionSafe // CmTeasableItemSafe
	private _slides: CmSlideImpl[]

	constructor(data: unknown, locale?: string) {
		super(data, locale)
		// by extending CmTeaserImpl we are getting a bunch of data from the teaser and now the same data plus teasbleItems
		// teasableItems do not exist on all teasers, they are part of collections
		this._parsedTeasableItems = CmCollectionSchema.parse(data)
		// this returns teasable items -- will need to be modified to get products. Will also need to find `CMProductTeaserImpl`
		this._slides = this._parsedTeasableItems.teasableItems.map((target) => new CmSlideImpl(target, this))
	}

	public getSliderStyle(): ContentCarouselStyle | undefined {
		return this.getViewtype() === 'bucket-content-carousel-flush' ? 'guided-shopping' : undefined
	}

	// These are more specific to the collection/ua bucket
	public getCarouselViewType() {
		// TODO: clean up lookup functions
		const settingLookupKey = this.getUaModuleSettings()?.mobileViewtype
		const setting = settingLookupKey && this.raw.uaModuleLinkSettings.find((obj) => obj && obj.key === settingLookupKey)

		if (!setting || !setting.value) return undefined
		const foundSetting = setting.value.find((obj) => obj && isLinkedViewType(obj) && obj?.layout)
		return foundSetting && isLinkedViewType(foundSetting) && foundSetting.layout ? foundSetting.layout : undefined
	}

	public getCarouselLayout(): ContentCarouselLayout {
		switch (this.getCarouselViewType()) {
			case 'bucket-content-carousel-mb':
				return 'carousel'
			case 'bucket-content-stack-mb':
				return 'stack'
			case 'bucket-content-grid-mb':
				return 'grid'
			case 'bucket-content-default-stack-mb':
				return 'stack'
		}
		return 'carousel'
	}

	public getMaxSlidesPerRow() {
		return this.raw.uaModuleSettings?.maxDisplayedSlides ?? 4 // set a default of 4 if nothing is found
	}

	// All sliders on mobile have 1 full slide, the "grid" variation has 2 full slides visible
	public getMaxSlidesPerRowMobile() {
		return this.getCarouselLayout() === 'grid' ? 2 : 1
	}

	public hasNumberedSlides() {
		return this.raw.uaModuleSettings?.numberSlides
	}

	public useAdvancedProductTiles() {
		return this.raw.uaModuleSettings?.useSFCCProductTile
	}

	get slides(): CmSlideImpl[] {
		return this._slides
	}
}

function placementHasContentCarousel(items: CmLinkable[]) {
	return items?.some((content) => isCmUaBucket(content) && content?.viewtype === 'bucket-content-carousel')
}

function placementHasArticle(items: CmLinkable[]) {
	return items?.some((content) => isCmArticle(content))
}

function placementLocator(pageContent: PageContentData, placementType: 'carousel' | 'article') {
	const placements = pageContent?.placements.filter((p) => !!getListOfItemsInPlacement(p))

	if (!placements.length) return false

	for (let i = 0; i < placements.length; i++) {
		const items = getListOfItemsInPlacement(placements[i])
		const isFound = placementType === 'carousel' ? placementHasContentCarousel(items) : placementHasArticle(items)
		if (isFound) return true
	}
	return false
}

export function pageHasContentCarousel(pageContent: PageContentData) {
	return placementLocator(pageContent, 'carousel')
}

export function pageHasArticles(pageContent: PageContentData) {
	return placementLocator(pageContent, 'article')
}

export async function getCarouselSlides(
	carouselSlides: ContentCarouselModule['slides'],
	locale: string,
): Promise<RenderableCarouselSlide[]> {
	const results = await Promise.all(
		carouselSlides.map((slide) => (slide.type === 'content' ? slide : getProductFromProductSlide(slide, locale))),
	)
	return ensureNonNullishArray(results)
}
