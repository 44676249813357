import type { ContentModule, ContentSourceTransformerRegistry } from '../../types'
import heroTransformer from './hero'

const registry: ContentSourceTransformerRegistry = [
	{
		type: 'hero',
		...heroTransformer,
	},
	//  NOTE: Add new transformers here as we define them.
]

export default function mcpTransformer(data: unknown): ContentModule | undefined {
	const moduleHandler = registry.find((m) => m.identifier(data))
	if (moduleHandler) {
		return moduleHandler.transformer(data)
	}

	return undefined
}
