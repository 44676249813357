import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import CallToAction from '../partials/CallToAction'
import Headline from '../partials/Headline'
import styles from './SeoGridLink.module.scss'

interface Props {
	data: CmTeasable
	locale: string
}

const SeoGridLink = ({ data, locale }: Props) => {
	return (
		<div id={data.id} className={`seo ${styles.seo}`}>
			<Headline data={data} locale={locale} />
			<CallToAction parent={data} teaserTargets={data.teaserTargets} className={styles.seo__list} locale={locale} />
		</div>
	)
}

export default SeoGridLink
