/* eslint-disable  @typescript-eslint/no-explicit-any */
import { createClientLogger } from '~/lib/logger'
import { getEvergageCampaignData } from '~/lib/client-only/mcp/evergage'
import { McpCampaignTypes, McpImagesTypes } from '~/lib/client-only/mcp/mcp.constants'
import { type HeroContentModule } from '~/lib/client-server/cms/modules/hero'
import { McpHeroImpl } from '../shared/hero'
import { ContentSource } from '~/lib/client-server/cms/types'

export const logger = createClientLogger('personalization-transform')

export default {
	identifier: (data: any): boolean => {
		if (data) {
			const campaignData = getEvergageCampaignData(McpCampaignTypes.HERO)
			if (campaignData) {
				return data?.campaign === campaignData?.campaign?.campaignId || data?.campaign === 'testCampaignId' // For Preview, campaigns will not have unique campaign id's and `testCampaignId` will be default.
			}
		}
		return false
	},
	transformer: (data: any): HeroContentModule | undefined => {
		try {
			const hero = new McpHeroImpl(data)

			return {
				type: 'hero',
				source: ContentSource.MCP,
				theme: hero.moduleTheme,
				style: hero.moduleStyle,
				header: hero.header,
				subheader: hero.subheader,
				snipeText: hero.snipeText,

				settings: {
					isCenter: hero.textStyleCenter,
					titleStyle: hero.headerStyle,
					transparent: hero.transparent,
					width: hero.width,
					isTextLinks: hero.ctaLinks,
					posX: hero.posX,
					posY: hero.posY,
				},

				image: hero.image,
				mobile: {
					image: {
						id: hero.mobileImageURL,
						src: hero.mobileImageURL,
						alt: hero.mobileImageAlt,
						height: hero.imageDimensions('hei', McpImagesTypes.MOBILE),
						width: hero.imageDimensions('wid', McpImagesTypes.MOBILE),
						ctaLink: hero.mobileImageCTAURL,
					},
				},
				callsToAction: hero.ctaTargets,
				context: {
					campaign: hero.campaign,
					experience: hero.experience,
					userGroup: hero.userGroup,
					source: ContentSource.MCP,
					tracking: hero.analyticsTracking,
				},
			}
		} catch (e) {
			logger.error(e, 'Invalid input data received for rendering mcp Hero module')
			return undefined
		}
	},
}
