import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import MediaContent from '../partials/MediaContent'
import styles from './Article.module.scss'
import clsx from 'clsx'
import ElementTree from '~/components/shared/ElementTree'

interface Props {
	data: CmTeasable
	locale: string
	placement?: string
	className?: string
}

const Article = ({ data, className, locale }: Props) => {
	const content = data.teaserTitle
		? data.teaserTitle
		: data.detailText?.textAsTree && (
				<ElementTree
					locale={locale}
					element={data.detailText.textAsTree}
					refs={data.detailText?.textReferencedContent}
				/>
		  )
	return (
		<section className={clsx(styles.article, className)}>
			{content}
			<MediaContent locale={locale} className={styles.article__img} data={data} />
		</section>
	)
}

export default Article
