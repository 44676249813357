import type { AnalyticsContext, CallToAction, ImageReference, ModuleSettings, ModuleTheme } from '.'
import type { CmTextTreeSafe } from '../sources/coremedia/shared/schemas'
import type { Hotspot } from './hotspot'
import type { FeaturedProducts } from '~/lib/client-server/cms/modules/feature-products'
import type { ContentSource } from '~/lib/client-server/cms/types'

export enum HeroStyle {
	Mini,
	Overlay,
	CategoryHero,
	Split,
}

export interface HeroContentModule {
	type: 'hero'
	source: ContentSource
	viewtype?: string
	theme?: ModuleTheme
	// Currently, if there is no defined style, the `HeroSlot` will return null, so if we can't build a style, we won't build the module.
	style?: HeroStyle
	settings?: ModuleSettings

	// these should not reference `CoreMediaTextTree` it is a CoreMedia type. Ideally these are just strings of text. It is currently needed to return an tree based on how CM returns some data, with links in the text or additional styles.
	snipeText?: string
	header?: string | CmTextTreeSafe
	subheader?: string | CmTextTreeSafe

	image?: ImageReference
	images?: ImageReference[]

	featuredProducts?: FeaturedProducts | null

	// Since there can be two different images, the Hotspots can also be different
	hotspots?: Hotspot[]

	// While we develop mobile first - the mobile naming convention came from CoreMedia, it was added in addition to the defaults. This is keeping that design setting and system alive, but allowing the alternate/secondary image to be optional as well. Conditional logic will handle how the hero is rendered and if a different mobile image is used. When build the module, you should always default to the top level properties and only leverage this field if there are variances between the styles.
	// This concept could be expanded in some sort of a typed loop to create additional breakpoints/module variations if a tablet or other style were to be introduced
	mobile?: {
		settings?: ModuleSettings
		image?: ImageReference
		images?: ImageReference[]
		// Since there can be two different images, the Hotspots can also be different
		hotspots?: Hotspot[]
	}

	// no defined max, but there should only be max 2 buttons
	callsToAction?: CallToAction[]

	// analytics from MCP/CM
	context?: AnalyticsContext
}
