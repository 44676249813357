import { useCallback, useState } from 'react'

/**
 * This hook allows you to manage the visibility state of a modal
 * @example
 * const [isModalOpen, handleModalOpen, handleModalClose] = useModal()
 * <Modal isOpen={isModalOpen} onClose={handleModalClose} ... />
 * @returns
 */
export function useModal() {
	const [isOpen, setIsOpen] = useState(false)
	const handleClose = useCallback(() => setIsOpen(false), [])
	const handleOpen = useCallback(() => setIsOpen(true), [])
	return [isOpen, handleOpen, handleClose] as const
}
