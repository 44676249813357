import clsx from 'clsx'
import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import { ensureString } from '~/types/strict-null-helpers'

import { moduleSettingsMap } from '~/components/cms/legacy/module-settings-map'
import MediaContent from '~/components/cms/legacy/partials/MediaContent'
import Teaser from '~/components/cms/legacy/partials/Teaser'
import { getModuleLinkSettings } from '~/lib/cms/legacy/helper'

import styles from './GeneralBanner.module.scss'

interface Props {
	data: CmTeasable
	position: number
	placement?: string
	locale: string
}

const GeneralBanner = ({ data, position, locale, placement }: Props) => {
	const desktopLinkedStyle = () => {
		const classesDesktop: string[] = []
		/**
		 * This is looking up the viewtype - which corresponds with the CMTeaser type in CM. There are hidden/default params in the CM code that set basics of the module. Using the module-settings.map.ts to pull these settings in and assign the classes as it builds... this is likely a temp solution but should speed up the dev process on the blocks
		 *
		 * THIS SHOULD BE EXTRACTED IN TO IT'S OWN FUNCTION OR HELPER FILE. THIS CODE WILL BE NEEDED TO BUILD OTHER MODULES OR BLOCKS.
		 *
		 * Thought would be: classesDesktop.push(settingsLookup(viewtype)) or something where you can pass the viewtype in and return the class or null.
		 */
		classesDesktop.push((data?.viewtype && moduleSettingsMap?.[data.viewtype]?.additionalClass) ?? '')

		const lookupDesktopTextColor = getModuleLinkSettings(
			data.uaModuleLinkSettings,
			data.uaModuleSettings?.desktopLinkedStyles || null,
			false,
		)
		const textColorDefaultDesktop = lookupDesktopTextColor || 'module--dark-text-dt'
		classesDesktop.push(textColorDefaultDesktop)

		const desktopContentDirectionLookup =
			data.uaModuleSettings?.desktopDirection ||
			(data?.viewtype && moduleSettingsMap?.[data.viewtype]?.desktopDirection)
		classesDesktop.push(desktopContentDirectionLookup ? 'module--right-dt' : 'module--left-dt')

		const classesExplodedDesktop = classesDesktop.join(' ')
		return classesExplodedDesktop
	}

	const mobileLinkedStyle = () => {
		const classesMobile: string[] = []

		const uaMobileStyles = data.uaMobileLayoutSettings ? data.uaMobileLayoutSettings?.styleClass : ''
		classesMobile.push(uaMobileStyles)

		const textColorMobileLookup = getModuleLinkSettings(
			data.uaModuleLinkSettings,
			data.uaModuleSettings?.mobileLinkedStyles || null,
		)
		const textColorDefaultMobile = textColorMobileLookup || 'module--dark-text-mb'
		classesMobile.push(textColorDefaultMobile)

		const mobileDirectionLookup =
			data.uaModuleSettings?.mobileDirection || (data?.viewtype && moduleSettingsMap?.[data.viewtype]?.mobileDirection)
		classesMobile.push(mobileDirectionLookup ? 'module--right-mb' : 'module--left-mb')

		const classesExplodedMobile = classesMobile.join(' ')
		return classesExplodedMobile
	}

	const customTeaser = (data?.viewtype && moduleSettingsMap[data.viewtype]?.copyAreaView) !== undefined
	const invertMediaPositioning = (data?.viewtype && moduleSettingsMap[data.viewtype]?.invertMedia) !== undefined // legacy support
	const hideTeaser = (data?.viewtype && moduleSettingsMap[data.viewtype]?.hideTeaser) !== undefined // legacy support

	const isPriority = position === 0

	return (
		<div
			id={`cm-${data?.viewtype}`}
			className={clsx(
				'cm-module module__wrapper',
				desktopLinkedStyle(),
				mobileLinkedStyle(),
				data?.viewtype && styles[data.viewtype],
				customTeaser && 'module--stickyMobileNav-wrapper',
			)}
		>
			<div className="module__primary-wrapper">
				<MediaContent
					// className={styles.video__cursor}
					locale={locale}
					data={data}
					priority={isPriority}
					mediaPosition={invertMediaPositioning ? 1 : 0}
					placement={ensureString(placement)}
					module={ensureString(data.viewtype)}
				/>

				{!hideTeaser && <Teaser locale={locale} data={data} enableCustomTeaser={customTeaser} />}
			</div>
			<MediaContent
				locale={locale}
				data={data}
				className={clsx('module__secondary-img', styles.video__cursor)}
				mediaPosition={invertMediaPositioning ? 0 : 1}
				placement={ensureString(placement)}
				module={ensureString(data.viewtype)}
			/>
		</div>
	)
}

export default GeneralBanner
