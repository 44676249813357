import dynamic from 'next/dynamic'
import Image from '~/components/primitives/Image'
import CmsText from '~/components/cms/shared/CmsText'
import PlayOutlineIcon from '~/components/primitives/icons/PlayOutlineIcon'
import ExpandViewIcon from '~/components/primitives/icons/ExpandViewIcon'
import { scene7RecipeBuilder } from '~/lib/scene7-recipes'
import { useModal } from '~/components/hooks/useModal'

import type { StoryPlayerModule } from '~/lib/client-server/cms/modules/storyplayer'

import styles from './InGridStoryPlayer.module.scss'

export interface InGridStoryPlayerProps
	extends Pick<StoryPlayerModule, 'title' | 'description' | 'media' | 'mobile' | 'posts' | 'profile' | 'theme'> {
	locale: string
	isPriority: boolean
}

const StoryPlayerDialog = dynamic(() => import('~/components/cms/StoryPlayerDialog/StoryPlayerDialog'), {
	ssr: false,
})

function InGridStoryPlayer({
	description,
	isPriority,
	locale,
	media,
	mobile,
	posts,
	profile,
	theme,
	title,
}: InGridStoryPlayerProps) {
	const [isModalOpen, handleModalOpen, handleModalClose] = useModal()

	/* TODO: currently using scene7RecipeBuilder to generate the image url. Right now the current regex that's applied in the UCI is catching an edge case for something like SS23_COG_Project_Rock_FTW_305_4_5, where it interprets the 305 as the width so the aspect ratio becomes 305_4. The way to fix this is most likely to look for only single digit numbers which would be /_([0-9])_([0-9])_?([0-9])?/g.
	 */
	const hasImages = !!media?.id || !!mobile?.media?.id
	return (
		<div className={styles.storyplayer} data-theme-cms={theme}>
			<div className={styles.storyplayer__ingrid}>
				{hasImages && media?.src && (
					<div
						role="button"
						tabIndex={0}
						onClick={handleModalOpen}
						className={styles['storyplayer__ingrid-media']}
						onKeyDown={(e) => {
							if (e.key === 'Enter' || e.key === ' ') handleModalOpen()
						}}
					>
						{/* 
						- loading both mobile and desktop images, hiding them with css based on mobile/desktop media queries
						*/}

						{media?.src && (
							<Image
								src={scene7RecipeBuilder(media?.id, 296, 370)}
								alt={media.alt}
								height={5}
								width={4}
								layout="responsive"
								priority={isPriority}
								unoptimized
								className={styles['storyplayer__hide-mb']}
							/>
						)}
						{mobile?.media?.src && (
							<Image
								src={scene7RecipeBuilder(mobile?.media?.id, 296, 370)}
								alt={mobile.media.alt}
								height={5}
								width={4}
								layout="responsive"
								priority={isPriority}
								unoptimized
								className={styles['storyplayer__hide-dt']}
							/>
						)}

						<div className={styles['storyplayer__ingrid-media-icon']}>
							<PlayOutlineIcon size="MD" className={styles['storyplayer__ingrid-media-icon-play']} />
							<ExpandViewIcon size="MD" className={styles['storyplayer__ingrid-media-icon-expand']} />
						</div>
					</div>
				)}

				<div className={styles['storyplayer__ingrid-copyarea']} tabIndex={0}>
					{title && (
						<CmsText text={title} locale={locale} className={styles['storyplayer__ingrid-copyarea-primary-text']} />
					)}
					{description && (
						<CmsText
							text={description}
							locale={locale}
							className={styles['storyplayer__ingrid-copyarea-secondary-text']}
						/>
					)}
				</div>
			</div>

			{isModalOpen && (
				<StoryPlayerDialog
					posts={posts}
					profile={profile}
					isModalOpen={isModalOpen}
					onClose={handleModalClose}
					locale={locale}
				/>
			)}
		</div>
	)
}

export default InGridStoryPlayer
