/* eslint-disable no-underscore-dangle */
import type {
	ContentCarouselSlideLayout,
	ContentSlide,
	ProductSlide,
} from '~/lib/client-server/cms/modules/content-carousel'
import type { CmCollectionImpl } from './collection'
import { CmModuleImpl } from './module'
import { CmContentSlide } from './content-slide'
import logger from '~/lib/logger'
import { CmContentSlideProduct } from './content-product'
import { ContentSource } from '~/lib/client-server/cms/types'

export class CmSlideImpl extends CmModuleImpl {
	private _parsedSlide
	private _parentData: CmCollectionImpl
	private _slide // : ContentCarouselSlide | null

	constructor(item: unknown, collection: CmCollectionImpl) {
		// this cannot handle products right now and it feels like there is a lot of data being passed around but things work and get the data needed
		super(item)
		this._parsedSlide = item // CmTeasableItemsSchema.parse(item)
		this._slide = this._parsedSlide // need to validate this item and figure out what type it is to return data
		this._parentData = collection
	}

	toSlide(): ContentSlide | ProductSlide | undefined {
		if (!this.slideType) return undefined
		return this.slideType === 'product' ? this.toProductSlide() : this.toContentSlide()
	}

	toProductSlide(): ProductSlide | undefined {
		const productSlide = new CmContentSlideProduct(this._slide)
		try {
			return {
				type: 'product',
				productId: productSlide.productId,
				faceOutColor: productSlide.faceOutColor,
				useAdvancedTile: this._parentData.useAdvancedProductTiles(),
			}
		} catch (e) {
			logger.error('Invalid input data received for rendering product slide module: ', e)
			return undefined
		}
	}

	toContentSlide(): ContentSlide | undefined {
		try {
			const contentSlide = new CmContentSlide(this._slide, this.locale)
			const desktopImage = contentSlide.getImage('desktop')
			// if there is no mobile image, get the desktop as a fall back. desktop is primary/default in coremedia
			const mobileImage = contentSlide.getImage('mobile') ?? desktopImage

			return {
				type: 'content',
				layout: this.slideLayout,
				isNumbered: this._parentData.hasNumberedSlides(),
				icon: contentSlide.icon,
				image: desktopImage
					? {
							id: desktopImage.id,
							src: desktopImage.src,
							alt: desktopImage.alt,
							height: desktopImage.height,
							width: desktopImage.width,
					  }
					: undefined,
				title: contentSlide.getHeader(),
				description: contentSlide.getSubheader(),
				callsToAction: contentSlide.getTeaserTargets().map((t) => t.toUniversal()),

				mobile: {
					image: mobileImage
						? {
								id: mobileImage.id,
								src: mobileImage.src,
								alt: mobileImage.alt,
								height: mobileImage.height,
								width: mobileImage.width,
						  }
						: undefined,
				},

				context: {
					source: ContentSource.COREMEDIA,
					tracking: contentSlide.analyticsTracking,
				},
			}
		} catch (e) {
			logger.error('Invalid input data received for rendering content slide module: ', e)
			return undefined
		}
	}

	get slideType() {
		switch (this._slide.type) {
			// ImageMap (hotspots) are not supported right now but have been set up for the App
			// case 'CMImageMap':
			case 'CMTeaser':
				return 'content'
			case 'CMProductTeaser':
				return 'product'
		}
		return undefined
	}

	// Control the return of the slide layout by changing the layout value to the prop type needed
	get slideLayout(): ContentCarouselSlideLayout {
		switch (this._parentData.getCarouselViewType()) {
			case 'bucket-content-carousel-mb':
				return 'column'
			case 'bucket-content-stack-mb':
				return 'row'
			case 'bucket-content-grid-mb':
				return 'column'
			case 'bucket-content-default-stack-mb':
				return 'column'
		}
		return 'column'
	}
}
