/* eslint-disable no-underscore-dangle */
import { isCoreMediaType, type CoreMediaType } from '~/lib/cms/legacy/type-guard'
import { CmModuleImpl } from '../shared/module'

/**
 * The CoreMedia teaser is really the basis of most content in CoreMedia.  Most other
 * object types are forms of the Teaser.  That is not ideal but it is what we have today.
 * In some cases, in code, we will represent an object that is a teaser as something else
 * only for more readability.  For example, the media object is actually a teaser but it
 * shouldn't really be.
 */
export class CoreMediaLegacy extends CmModuleImpl {
	private _processedLegacyModule: CoreMediaType

	constructor(data: unknown, locale?: string) {
		super(data, locale)
		if (!isCoreMediaType(data)) {
			throw new Error('Cannot create CoreMediaLegacy from non-coremedia data')
		}
		this._processedLegacyModule = data
	}

	public get legacyData(): CoreMediaType {
		return this._processedLegacyModule
	}
}
