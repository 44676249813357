/* eslint-disable no-underscore-dangle */
import { CmTeaserImpl } from './teaser'
import { CmMediaImpl } from './media'
import { CmStoryPlayerPostImpl } from './storyplayer-post'
import { CmCollectionSchema, type CmCollectionSafe } from './schemas'
import type { DeviceType } from './module'
import logger from '~/lib/logger'

export class CmStoryPlayerImpl extends CmTeaserImpl {
	private _parsedTeasableItems: CmCollectionSafe
	private _posts: CmStoryPlayerPostImpl[]

	constructor(data: unknown, locale?: string) {
		super(data, locale)
		this._parsedTeasableItems = CmCollectionSchema.parse(data)
		this._posts = this._parsedTeasableItems.teasableItems.map((target) => new CmStoryPlayerPostImpl(target, locale))
	}

	getImage(deviceType: DeviceType): CmMediaImpl | undefined {
		const image = this.getMedia(deviceType)?.[0]
		if (!image) {
			logger.warn(`No image associated with storyplayer module "${this.getModuleName()}"`)
			return undefined
		}
		return new CmMediaImpl(image)
	}

	get author() {
		return this.raw.authors?.[0]
	}

	getAuthorMedia() {
		const media = this.author?.uaMedia?.[0]
		if (!media) {
			logger.warn(`No image associated with storyplayer module - author media"${this.getModuleName()}"`)
			return undefined
		}
		return new CmMediaImpl(media)
	}

	get authorName() {
		return this.author?.displayName
	}

	get authorImage() {
		return this.getAuthorMedia()?.toImage()
	}

	get posts(): CmStoryPlayerPostImpl[] {
		return this._posts
	}
}
