import type { ApolloClient } from '@apollo/client'
import type { CmProductTeaser } from '~/graphql/generated/coremedia/type-document-node'
import { GetProductTileByProductIdDocument } from '~/graphql/generated/uacapi/type-document-node'
import type {
	GetProductTileByProductIdQuery,
	MasterProduct,
	VariantProduct,
} from '~/graphql/generated/uacapi/type-document-node'
import { isProductUpc, isVariantProductByTypeName, mapMasterToTile, mapVariantToTile } from '~/lib/products'
import type { ClientProductTile } from 'lib/types/product.interface'
import { isCmExternalProduct, isCmTeaser } from './type-guard'
import { deepCopy } from '~/lib/utils'
import { ensureNonNullishArray } from '~/types/strict-null-helpers'

// START: Helper functions for querying UACAPI and processing data that could be either a MasterProduct, VariantProduct, or CmTeasable piece of Content. Since all functions are written in function declaration syntax, they are placed in order of both abstraction (highest to lowest) and then by order in which they're called to help reference. The first function here `getProductFromTeaser` is the only exported function and sets off the series of events to query UACAPI for the intended products set in CoreMedia and render them as expected into the view layer.

/**
 * Retrieves product data from a teasable item.
 *
 * @async
 * @function getProductsFromTeasers
 * @param teasableItem - Array of teasable items containing product information and potentially content teasers
 * @param client - instance of <ApolloClient> from useApolloClient hook
 * @returns An array of Promises that resolves to the mapped product data,
 * or `undefined` if there is an issue fetching the data.
 *
 */
export async function getProductsFromTeasers({ teasableItems, client }) {
	const results = await Promise.all(teasableItems.map((teasableItem) => getProductFromTeaser(teasableItem, client)))

	return ensureNonNullishArray(results)
}
/**
 * Retrieves product data from a teasable item.
 *
 * @async
 * @function getProductFromTeaser
 * @param teasableItem - The teasable item containing product information.
 * @param client - instance of <ApolloClient> from useApolloClient hook
 * @returns A Promise that resolves to the mapped product data,
 * or `undefined` if there is an issue fetching the data.
 *
 * @example
 * const locale = 'en-US';
 * const teasableItem = { productRef: { externalId: '123' } };
 * const productData = await getProductFromTeaser(locale, teasableItem);
 */
export async function getProductFromTeaser(teasableItem, client) {
	// Extract external ID from the Product Teaser
	const externalId = teasableItem?.productRef?.externalId

	// Check if the Teaser is an external product or if the external ID is available
	const isExternalProduct = isCmExternalProduct(teasableItem)

	const isCmContent = !isExternalProduct || !externalId

	// Return the original teasableItem if it's not an external product or lacks an external ID
	if (isCmContent) {
		return isCmTeaser(teasableItem) ? teasableItem : undefined
	}

	// Create a query input entry for fetching external product data
	const { isVariantQueriedByStyleId, queryInputEntry } = createQueryInputEntry(teasableItem, externalId)

	// Fetch external product data using the query input entry
	const response = await fetchExternalProductData(queryInputEntry, client)

	// Process the response and map it to the standardized product tile data shape
	if (response) {
		const mappedProduct = mapResponseToProductTileDataShape(response)

		if (!mappedProduct?.orderable) return undefined
		// If colors are present and variant querying is based on style ID, adjust the display order of colors
		if (mappedProduct?.colors && isVariantQueriedByStyleId) {
			const colorsArray = moveTargetColorToFirstIndexForProductTileDisplay(mappedProduct, teasableItem)

			// Update the colors in the mapped product
			if (colorsArray) {
				mappedProduct.colors = colorsArray
			}
		}

		// Return the final mapped product data
		return mappedProduct
	}

	// Return undefined if there is an issue fetching the data or if no data is found
	return undefined
}

// Start helper functions for getProductFromTeaser()
/**
 * Creates a query input entry based on the teasable item and external ID.
 *
 * @function createQueryInputEntry
 * @param teasableItem - The teasable item containing product information.
 * @param externalId - The external ID of the product.
 * @returns An object containing information for querying external product data.
 *
 * @example
 * const teasableItem = { uaSettings: { style: '123' } };
 * const externalId = '456';
 * const { isVariantQueriedByStyleId, queryInputEntry } = createQueryInputEntry(teasableItem, externalId);
 */
function createQueryInputEntry(teasableItem: CmProductTeaser, externalId: string) {
	const uaSettingsNestedStyleId: string | undefined = teasableItem?.uaSettings?.style
	const isVariant = externalId && isProductUpc(externalId)
	const isVariantQueriedByStyleId = !!(isVariant && uaSettingsNestedStyleId)
	const productIdType = isVariant && !isVariantQueriedByStyleId ? 'upc' : 'style'
	const productId = isVariantQueriedByStyleId ? uaSettingsNestedStyleId : externalId

	const queryInputEntry = { [productIdType]: `${productId}` }
	return { isVariantQueriedByStyleId, queryInputEntry }
}

/**
 * Fetches external product data based on the specified locale and query input entry.
 *
 * @async
 * @function fetchExternalProductData
 * @param locale - The locale for fetching product data.
 * @param queryInputEntry - The query input entry for retrieving external product data.
 * @returns A Promise that resolves to the fetched product data,
 * or `null` if there is an issue or no data is found.
 *
 * @example
 * const locale = 'en-US';
 * const queryInputEntry = { style: '123' };
 * const productData = await fetchExternalProductData(locale, queryInputEntry);
 */
export async function fetchExternalProductData(
	queryInputEntry: Record<string, string | undefined>,
	client: ApolloClient<object>,
) {
	const results = await client.query<GetProductTileByProductIdQuery>({
		query: GetProductTileByProductIdDocument,
		variables: { productId: queryInputEntry },
	})

	const product = results?.data?.product

	return product
}

/**
 * Maps a product response to the shape of a client product tile.
 *
 * @function mapResponseToProductTileDataShape
 * @param product - The product data to be mapped.
 * @returns The mapped product data in the client product tile shape,
 * or `undefined` if the input product is falsy.
 *
 * @example
 * const product = { MasterProduct | VariantProduct  }
 * const mappedProduct = mapResponseToProductTileDataShape(product);
 */
export function mapResponseToProductTileDataShape(
	product: MasterProduct | VariantProduct,
): ClientProductTile | undefined {
	if (!product) return undefined

	const isVariant = isVariantProductByTypeName(product)
	const mappedProduct = isVariant ? mapVariantToTile(product) : mapMasterToTile(product)
	return mappedProduct
}

/**
 * Locates the intended color within the "colors" array and moves it to the first index so it becomes the default color when passed into <ProductTile />
 *
 * @function moveTargetColorToFirstIndexForProductTileDisplay
 * @param product - The product data containing the "colors" array.
 * @param teasableItem - The teasable item containing the intended color information.
 * @returns An array containing the "colors" with the intended color moved to the first index,
 * or `undefined` if the target color, product colors, or target color index is not found.
 *
 * @example
 * const product = { colors: [...array of colors... ] };
 * const teasableItem = { uaSettings: { c_color: 'red' } };
 * const colorsArray = moveTargetColorToFirstIndexForProductTileDisplay(product, teasableItem);
 */
function moveTargetColorToFirstIndexForProductTileDisplay(product: ClientProductTile, teasableItem: CmProductTeaser) {
	const targetColor = teasableItem?.uaSettings?.c_color
	const productColors = product?.colors

	const targetColorIndex = productColors?.findIndex(
		(productColor) => productColor.color.toString() === targetColor?.toString(),
	)

	if (!targetColor || !productColors || !targetColorIndex) return undefined

	const colorsArray = deepCopy(productColors)
	const removedElement = colorsArray.splice(targetColorIndex, 1)[0]
	colorsArray.unshift(removedElement)

	return colorsArray
}
