import { useCallback, useEffect, useMemo, useRef, type ReactNode } from 'react'
import clsx from 'clsx'
import styles from './AnimatedSlot.module.scss'
import useReactiveActor from '~/components/hooks/useReactiveActor'
import { animatedSlotStateMachine } from './statemachines/animatedSlotStateMachine'

/**
 * The animation type.
 */
export type AnimationType = 'fade-out' | 'fade-in' | undefined

/**
 * Props for AnimatedSlot component
 */
export interface AnimatedSlotProps {
	/**
	 * The default content to be displayed. This content will fade out upon load when defaultAnimation is set to 'fade-out'.
	 */
	defaultContent: ReactNode

	/**
	 * The personalized content to be displayed on top of the default content. This content will fade in upon load when personalizedAnimation is set to 'fade-in'.
	 */
	personalizedContent?: ReactNode

	/**
	 * The animation to apply to the default content upon load. Usually this is fade-out. The animation is controlled by CSS keyframes.
	 * When default content is loaded, but personalized content is not available, then the parent component should not apply any class to the default content.
	 * This will typically result in the default content having an opacity of 1, since the component was previously rendered without the fade-out class present and
	 * will usually have been shown as the default content on the page. When we apply the fade-out class, the default content will have opacity 0 and
	 * the CSS keyframe animation of the fade-out class is set to transition from 1 to 0 opacity. This will give the fade-out effect.
	 */
	defaultAnimation?: AnimationType

	/**
	 * The animation to apply to the personalized content upon load. Usually this is fade-in. The animation is controlled by CSS keyframes.
	 * When personalized content is loaded, the parent component should apply the fade-in class to the personalized content at the same time.
	 * This will result in the personalized content having CSS keyframe animation of the fade-in class is set to transition from 0 to 1 opacity.
	 */
	personalizedAnimation?: AnimationType

	/**
	 * The function to set the height of the div.
	 */
	handleHeightUpdate: (height: number) => void

	/**
	 * Flag to indicate if the personalized content is still loading. This will apply a placeholder class if loadingPlaceholderEnabled is also true.
	 */
	isLoading?: boolean

	/**
	 * Flag to indicate if the loading placeholder should be enabled. This shows placeholder content while the personalized content is loading.
	 */
	loadingPlaceholderEnabled?: boolean
}

/**
 * Determines the appropriate CSS class based on animation state when loadingPlaceholderEnabled is false.
 *
 * @param {Object} params - The parameters for determining the animation class.
 * @param {string} params.animationType - The animation type (e.g., 'fade-out', 'fade-in').
 * @returns {string} - The CSS class name to apply for the animation.
 */
function getNonLoadingEnabledAnimatedClass({ animationType }: { animationType: AnimationType }): string | undefined {
	// Loading placeholder is disabled, apply the appropriate animation class to allow fade-out or fade-in
	if (animationType === 'fade-out') {
		return styles['animated_slot__fadeout-animation']
	}
	if (animationType === 'fade-in') {
		return styles['animated_slot__fadein-animation']
	}
	return undefined
}

/**
 * AnimatedSlot component
 *
 * This component operates in 2 modes:
 *
 * 1. When loadingPlaceholderEnabled is true, it will show a placeholder while loading is in progress and fade in the content when loading is complete. Loading is controlled by a prop from the parent component.
 * 2. When loadingPlaceholderEnabled is false, it will apply the default and personalized animations to the content, which are typically fade-out for default content and fade-in for personalized content.
 *
 * If no loading placeholder is enabled, this component positions the personalized content on top of the default content to allow the default to fade out
 * while the personalized fades in at the same time. If loading placeholder is enabled, a placeholder will be shown
 * while loading is in progress and the content (default or personalized) will be animated in when loading is complete
 *
 * @param {Object} props - The properties for the component.
 * @see AnimatedSlotProps
 */
export default function AnimatedSlot({
	defaultContent,
	personalizedContent,
	defaultAnimation,
	personalizedAnimation,
	handleHeightUpdate,
	isLoading,
	loadingPlaceholderEnabled,
}: AnimatedSlotProps) {
	const defaultContentRef = useRef<HTMLDivElement | null>(null)
	const personalizedContentRef = useRef<HTMLDivElement | null>(null)
	const [actorState] = useReactiveActor(animatedSlotStateMachine, {
		input: {
			loadingPlaceholderEnabled: !!loadingPlaceholderEnabled,
			isLoading: !!isLoading,
			defaultContent,
			personalizedContent,
			defaultAnimation,
			personalizedAnimation,
		},
	})

	const defaultContentAnimatedClass = useMemo(() => {
		if (
			actorState.matches({
				loadingPlaceholderEnabled: 'showLoadingPlaceholder',
			})
		) {
			// For the default content, apply the loading placeholder class when loading is in progress
			return undefined
		}

		// If loadingPlaceHolder is disabled or loading is complete, animate as instructed by passed props (usually fade-out)
		return getNonLoadingEnabledAnimatedClass({ animationType: defaultAnimation })
	}, [defaultAnimation, actorState])

	const personalizedContentAnimatedClass = useMemo(() => {
		if (
			actorState.matches({
				loadingPlaceholderEnabled: 'showLoadingPlaceholder',
			})
		) {
			// For the personalized content, apply no class when loading is in progress
			return undefined
		}

		// If loadingPlaceHolder is disabled or loading is complete, animate as instructed by passed props (usually fade-in)
		return getNonLoadingEnabledAnimatedClass({ animationType: personalizedAnimation })
	}, [personalizedAnimation, actorState])

	const shouldAnimate =
		actorState.matches('showDefaultAndPersonalizedContentWithAnimation') ||
		actorState.matches('showDefaultContentWithAnimation') ||
		actorState.matches('showPersonalizedContentWithAnimation')

	// By default, default content is displayed with position: relative and personalized content is displayed with position: absolute.
	// Update the height of the parent div due to positioning of the default and personalized content.
	// This is necessary to prevent the content from overlapping or being cut off, when the content is positioned absolutely.
	const updateHeight = useCallback(() => {
		if (shouldAnimate && defaultContentRef.current && personalizedContentRef.current) {
			const defaultContentHeight = defaultContentRef.current.offsetHeight
			const personalizedContentHeight = personalizedContentRef.current.offsetHeight
			// The use of Math.max here is to ensure that the height of the parent container is always sufficient to display the larger of the two contents - default or personalized.
			// Consider a scenario where you start with a large screen. If the personalized content is smaller than the default content, it's not an issue.
			// However, when you resize to a mobile view, the personalized content might become larger than the default content, and we update the height to accommodate this.
			// If you then resize back to a large screen, without Math.max, we would be left with the height that was set for the mobile view, which might not be appropriate for the large screen view.
			// Therefore, by using Math.max, we ensure that the height is always set to the larger of the two - the height of the default content or the personalized content. This prevents any issues with content cropping or overlapping when resizing the screen.
			const newHeight = Math.max(defaultContentHeight, personalizedContentHeight)
			handleHeightUpdate(newHeight)
		}
	}, [shouldAnimate, handleHeightUpdate])

	// Update the height of the parent div when:
	// 1. the component mounts
	// 2. the window is resized
	// 3. the size of the content changes
	useEffect(() => {
		// Update the height when the component mounts or shouldAnimate changes
		updateHeight()

		const resizeObserver = new ResizeObserver(updateHeight)

		// Store the current values of the refs in variables
		const defaultContentNode = defaultContentRef.current
		const personalizedContentNode = personalizedContentRef.current

		// Start observing the size of the default and personalized content
		if (defaultContentNode) {
			resizeObserver.observe(defaultContentNode)
		}
		if (personalizedContentNode) {
			resizeObserver.observe(personalizedContentNode)
		}

		// Add an event listener to update the height when the window is resized
		window.addEventListener('resize', updateHeight)

		return () => {
			if (defaultContentNode) {
				resizeObserver.unobserve(defaultContentNode)
			}
			if (personalizedContentNode) {
				resizeObserver.unobserve(personalizedContentNode)
			}

			window.removeEventListener('resize', updateHeight)
		}
	}, [updateHeight])

	if (actorState.matches('showNothing')) {
		return null
	}

	// Case 1: Loading placeholder is enabled - Show loading placeholder then animate to default or personalized content
	if (loadingPlaceholderEnabled) {
		const shouldShowDefaultContent =
			actorState.matches('showDefaultContentNoAnimation') ||
			actorState.matches('showDefaultContentWithAnimation') ||
			actorState.matches('showDefaultAndPersonalizedContentWithAnimation')

		const shouldAnimateDefaultContent =
			actorState.matches('showDefaultContentWithAnimation') ||
			actorState.matches('showDefaultAndPersonalizedContentWithAnimation')

		const shouldShowPersonalizedContent =
			actorState.matches('showPersonalizedContentNoAnimation') ||
			actorState.matches('showPersonalizedContentWithAnimation') ||
			actorState.matches('showDefaultAndPersonalizedContentWithAnimation')

		const shouldAnimatePersonalizedContent =
			actorState.matches('showPersonalizedContentWithAnimation') ||
			actorState.matches('showDefaultAndPersonalizedContentWithAnimation')

		// Show loading placeholder then animate to other content
		return (
			<>
				<div ref={defaultContentRef} className={clsx(styles.animated_slot__full_width)}>
					<div
						data-testid="loading-placeholder"
						className={clsx(
							styles.animated_slot__full_width_and_height_grey_box,
							styles.animated_slot__absolute_content,
							{
								[`${styles.animated_slot__make_not_visible}`]: !actorState.matches({
									loadingPlaceholderEnabled: 'showLoadingPlaceholder',
								}),
							},
						)}
					/>
					{/* When personalized content is shown, we don't render the default content */}
					{!shouldShowPersonalizedContent && (
						<div
							data-testid="default-content-with-loading-placeholder"
							className={clsx(styles.animated_slot__full_width, {
								// When loading is enabled is need the content to be physically shown in order for the parent container
								// to have a height and width determined by the image size. This is necessary to prevent the parent container
								// from collapsing and causing the page to jump when the default or personalized content is eventually loaded.
								// At a minimum, the parent container will have the height and width of the default content. If the personalized
								// content later loads and is larger, the parent container will expand to accommodate the larger content.
								// We apply `visibility: hidden` as the browser will "hide" the content but it will still take up space.
								// Secondly, we set the background to the same color as the grey box to make sure that at a minimum, the default image
								// itself has a background color that matches the grey box.
								[`${styles.animated_slot__make_not_visible} ${styles.animated_slot__full_width_and_height_grey_box}`]:
									actorState.matches({
										loadingPlaceholderEnabled: 'showLoadingPlaceholder',
									}),
								// When loading is complete, and the default content is not being shown, we hide the default content
								[`${styles.animated_slot__hide_content}`]:
									!actorState.matches({ loadingPlaceholderEnabled: 'showLoadingPlaceholder' }) &&
									!shouldShowDefaultContent,
								// When loading is complete, and the default content is being shown with animation, we apply the animation to the default content
								[`${defaultContentAnimatedClass}`]: shouldAnimateDefaultContent,
							})}
						>
							{defaultContent}
						</div>
					)}
					{/* When personalized content is available, default content is not shown, and personalized content is shown */}
					{!shouldShowDefaultContent && personalizedContent && shouldShowPersonalizedContent && (
						<div
							data-testid="personalized-content-with-loading-placeholder"
							className={clsx(styles.animated_slot__full_width, {
								// When loading is complete, and the personalized content is being shown with animation, we apply the animation to the personalized content
								[`${personalizedContentAnimatedClass}`]: shouldAnimatePersonalizedContent,
								// When loading is complete, and the personalized content is not being shown OR default content is being shown, we hide the personalized content
								[`${styles.animated_slot__hide_content}`]: !shouldShowPersonalizedContent || shouldShowDefaultContent,
							})}
						>
							{personalizedContent}
						</div>
					)}
				</div>
			</>
		)
	}

	const shouldShowPersonalizedContent =
		actorState.matches('showPersonalizedContentNoAnimation') ||
		actorState.matches('showPersonalizedContentWithAnimation') ||
		actorState.matches('showDefaultAndPersonalizedContentWithAnimation')
	const shouldAnimatePersonalizedContent =
		actorState.matches('showPersonalizedContentWithAnimation') ||
		actorState.matches('showDefaultAndPersonalizedContentWithAnimation')
	// Technically in Case 2 we'll always be showing the default content no matter what, but for future
	// proofing this component we should still respond to the actual machine state in order to determine
	// what sort of rendering should happen
	const shouldShowDefaultContent =
		actorState.matches('showDefaultContentNoAnimation') ||
		actorState.matches('showDefaultContentWithAnimation') ||
		actorState.matches('showDefaultAndPersonalizedContentWithAnimation') ||
		actorState.matches('loadingPersonalizedContentShowingDefaultContent')
	const shouldAnimateDefaultContent =
		actorState.matches('showDefaultContentWithAnimation') ||
		actorState.matches('showDefaultAndPersonalizedContentWithAnimation')

	// Case 2: Loading placeholder is disabled - Show default and transition to personalized if available
	return (
		<>
			{shouldShowDefaultContent && (
				<div
					ref={defaultContentRef}
					data-testid="default-content"
					className={clsx({ [`${defaultContentAnimatedClass}`]: shouldAnimateDefaultContent })}
				>
					{defaultContent}
				</div>
			)}
			{/* Note! We don't need this for all slots when haven't personalization. E.g: we pass personalizedContent for hero section */}
			{shouldShowPersonalizedContent && (
				<div
					ref={personalizedContentRef}
					data-testid="personalized-content"
					className={clsx(styles.animated_slot__absolute_content, styles.animated_slot__full_width, {
						[`${personalizedContentAnimatedClass}`]: shouldAnimatePersonalizedContent,
					})}
				>
					{personalizedContent}
				</div>
			)}
		</>
	)
}
