'use client'

/* eslint-disable import/no-unresolved */
import 'swiper/css'
import { useMemo, useState } from 'react'
import clsx from 'clsx'
import { Keyboard, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import BucketFourUp from '~/components/cms/legacy/BucketFourUp/BucketFourUp'
import CMLink from '~/components/cms/legacy/partials/CMLink'
import Headline from '~/components/cms/legacy/partials/Headline'
import MediaItem from '~/components/cms/legacy/partials/MediaItem'
import Subheadline from '~/components/cms/legacy/partials/Subheadline'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useProductsFromCollection } from '~/components/hooks/cms/legacy/useProductsFromCollection'
import Image from '~/components/primitives/Image'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import Slider from '~/components/shared/Slider/Slider'
import type { CmCollection } from '~/graphql/generated/coremedia/type-document-node'
import {
	getLegacyStyles,
	getMappedSettingImageRecipe,
	getMappedSettingStyles,
	getMobileStyleClass,
	getModuleTextStyling,
	getUrlFromLinkObject,
} from '~/lib/cms/legacy/helper'
import { isCmTeaser, isLinkedSettingLinkable } from '~/lib/cms/legacy/type-guard'
import { scene7RecipeBuilder } from '~/lib/scene7-recipes'
import { ensureBoolean, ensureString } from '~/types/strict-null-helpers'
import { getCarouselCount, hasMobileCarousel, hasAdvancedProductTiles } from '~/lib/cms/legacy/bucket'

import styles from './Bucket.module.scss'
import { useLocale } from '~/components/hooks/useLocale'
import { ProductTile } from '~/components/shared/ProductTile/ProductTile'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'
import { UaSkeleton } from '~/components/primitives/UaSkeleton/UaSkeleton'

interface BucketProps {
	data: CmCollection
	placement?: string
	locale: string
}

const Bucket = ({ data, placement, locale }: BucketProps) => {
	const viewtype = ensureString(data.viewtype)
	/**
	 * General information
	 *
	 * This block is designed to mirror the behaviors of the CMCollection.generalModule
	 * in the CoreMedia repo. That file is bascially a compilier of the data.
	 *
	 * This block basically will take any Collection and process it as needed.
	 */
	const { carouselItems } = useProductsFromCollection(data?.teasableItems)
	const slides = data.teasableItems

	// going to need this to break the carousel for certain blocks.
	const isCarouselMobile = hasMobileCarousel(data)
	const columnCountMobile = getCarouselCount(data, 'mobile')
	const columnCountDesktop = getCarouselCount(data, 'desktop')

	const imageRecipeMobile = getMappedSettingImageRecipe(viewtype, 'mobile')
	const imageRecipeDesktop = getMappedSettingImageRecipe(viewtype, 'desktop')

	const mobileStyles = getMobileStyleClass(data)
	const mappedStyles = getMappedSettingStyles(viewtype)
	const legacyStyles = getLegacyStyles(data)

	// checks to see if CM Advanced Product Tiles is selected to display color tiles, wishlist feature, badges, and promo messaging
	const cmAdvancedProductTilesEnabled = hasAdvancedProductTiles(data)

	// Hacky way of changing what tile is rendered inside of the slider
	const sliderType = useMemo(() => {
		let sliderDisplay = 'CarouselItem'

		carouselItems?.forEach((item) => {
			const isTeaser = !!isCmTeaser(item)
			if (!isTeaser) sliderDisplay = 'ProductTile'
		})

		return sliderDisplay
	}, [carouselItems])
	/**
	 * Logic to disable slider and render the 4 up grid:
	 * 		If there are 4 slides (the max any of our sliders have)
	 * AND 	If there is NOT a mobile slider
	 * AND 	If the sliderType is a CarouselItem
	 *
	 * Render as a 2x2 grid on mobile and a 1x4 on desktop.
	 */
	const disableSlider = useMemo(() => {
		if (slides && slides?.length === 4 && !isCarouselMobile && sliderType === 'CarouselItem') {
			return true
		}
		return false
	}, [isCarouselMobile, sliderType, slides])

	return (
		<div className={clsx(`cm-module cm-list module__wrapper`, mobileStyles, mappedStyles, legacyStyles)}>
			<Headline data={data} className="module__primary-text module__text-headline4" locale={locale} />
			{disableSlider ? (
				<BucketFourUp locale={locale} module={ensureString(data.viewtype)} slides={slides} placement={placement} />
			) : (
				<Slider
					locale={locale}
					placement={ensureString(placement)}
					module={ensureString(data.viewtype)}
					sliderType={sliderType}
					slides={carouselItems}
					isCarouselMobile={isCarouselMobile}
					columnCountMobile={columnCountMobile}
					columnCountDesktop={columnCountDesktop}
					imageRecipeMobile={imageRecipeMobile ?? ['recipe-bucket-3up']}
					imageRecipeDesktop={imageRecipeDesktop ?? ['recipe-bucket-3up']}
					cmAdvancedProductTilesEnabled={cmAdvancedProductTilesEnabled}
				/>
			)}
		</div>
	)
}

function Scattered({ data, placement, locale }: BucketProps) {
	const { isMobile } = useLayoutMeasurement()
	const primaryTeaser = data.teasableItems?.[0]
	const primaryLink = primaryTeaser?.teaserTargets?.find((t) => t?.callToActionEnabled && t?.callToActionText)
	const primaryImage =
		isMobile && primaryTeaser?.mobileMedia?.[0] ? primaryTeaser.mobileMedia[0] : primaryTeaser?.media?.[0]
	const desktopTextStyle = getModuleTextStyling(data, 'desktop')

	if (!primaryTeaser) return null
	return (
		<div
			className={clsx(styles['bucket-scattered'], { [styles['dark-background']]: desktopTextStyle === 'light-text' })}
		>
			<Headline data={data} className={styles.headline} locale={locale} />
			<div>
				<div className={styles['image-primary']}>
					<CMLink
						locale={locale}
						parent={primaryTeaser}
						link={primaryLink}
						placement={ensureString(placement)}
						module={ensureString(data.viewtype)}
					>
						<div>
							<Image
								src={scene7RecipeBuilder(primaryImage?.name, 448, 560)}
								width={448}
								height={560}
								alt={primaryImage?.alt || ''}
							/>
							<p>{primaryLink?.callToActionText}</p>
						</div>
					</CMLink>
				</div>
			</div>
			<div>
				{data.teasableItems?.map((teasable, idx) => {
					const image = isMobile && teasable?.mobileMedia?.[0] ? teasable.mobileMedia[0] : teasable?.media?.[0]
					const imageSrc = scene7RecipeBuilder(image?.name, 330, 330)
					const teaserLink = teasable?.teaserTargets?.find((t) => t?.callToActionEnabled && t?.callToActionText)
					if (!teasable) return null
					return (
						<div key={idx} className={styles['image-secondary']}>
							<CMLink
								locale={locale}
								parent={teasable}
								link={teaserLink}
								placement={ensureString(placement)}
								module={ensureString(data.viewtype)}
							>
								<div>
									<Image src={imageSrc} width={330} height={330} alt={image?.alt || ''} />
									<p>{teaserLink?.callToActionText}</p>
								</div>
							</CMLink>
						</div>
					)
				})}
			</div>
		</div>
	)
}

function HorizontalScroll({ data, placement }: BucketProps) {
	/**
	 * NO DARK MODE SUPPORTED.
	 *
	 * Designs did not ask for, nor do we currently have a way of changing the styles in the product tile.
	 * This could become available if we added support for a dark variation in the product tile.
	 */
	const formatMessage = useFormatMessage()
	const locale = useLocale()
	const { carouselItems } = useProductsFromCollection(data?.teasableItems)
	const [slideProgress, setSlideProgress] = useState<number>(0)

	/**
	 * This is a temporary workaround (2/22/23). The CMColleciton, which builds these modules, data does not have all of the needed data
	 * in `teaserTargets`. The data does also live the `uaModuleLinkSettings`. Below is a temporary hack to get the value form there.
	 * This is only needed on the HorizonalScroll Module. There is an open ticket for standardizing the data returned: EPMD-8823
	 */
	const callToActionCustomLinkLookup = data?.uaModuleLinkSettings?.find(
		(obj) => obj?.key === 'path:/callToActionCustomLink',
	)
	const callToActionLookupValue =
		callToActionCustomLinkLookup?.value?.[0] && isLinkedSettingLinkable(callToActionCustomLinkLookup.value[0])
			? callToActionCustomLinkLookup.value[0]
			: undefined
	const callToActionText = data?.teaserTargets?.[0]?.callToActionText
	const cmAdvancedProductTilesEnabled: boolean = ensureBoolean(data.uaModuleSettings.useSFCCProductTile)
	const callToActionUrl = callToActionLookupValue ? getUrlFromLinkObject(callToActionLookupValue, locale) : undefined

	return (
		// eslint-disable-next-line local-rules/disallow-untranslated-literals-in-jsx
		<div className={clsx(styles['bucket-horizontal'])} role="region" aria-label={`${data.name} slider`}>
			<div className={styles['header-wrapper']}>
				<Headline data={data} className={styles.headline} locale={locale} />

				{callToActionUrl && <LocaleLink href={callToActionUrl}>{callToActionText}</LocaleLink>}
			</div>

			<Swiper
				className={styles['slider-wrapper']}
				modules={[Navigation, Keyboard]}
				slidesPerView={1.5}
				spaceBetween={8}
				slidesOffsetAfter={16}
				breakpoints={{
					768: {
						slidesPerView: 4,
						spaceBetween: 22,
						slidesOffsetAfter: 0,
					},
					1023: {
						slidesPerView: 4,
						spaceBetween: 44,
						slidesOffsetAfter: 0,
					},
				}}
				navigation={{
					enabled: true,
					prevEl: '.prev',
					nextEl: '.next',
				}}
				keyboard={{ enabled: true }}
				scrollbar={{ draggable: true }}
				onSlideChange={(e) => setSlideProgress(e.progress)}
			>
				{carouselItems && carouselItems.length > 0 ? (
					carouselItems.map(
						(item, index) =>
							item && (
								<SwiperSlide key={index}>
									{'price' in item ? ( // used to type narrow. there are two different types in here, 'price' is not on a CMTeasable.
										<ProductTile
											key={index}
											product={item}
											showColorInfo={cmAdvancedProductTilesEnabled}
											wishListEnabled={cmAdvancedProductTilesEnabled}
											badgesEnabled={cmAdvancedProductTilesEnabled}
											promotionsEnabled={cmAdvancedProductTilesEnabled}
											inSwiper={true}
											isExperimentalView
										/>
									) : (
										<div>
											<MediaItem
												locale={locale}
												data={item}
												placement={ensureString(placement)}
												module={ensureString(data.viewtype)}
											/>
											<div className={styles['slide-content']}>
												<Headline data={item} className={styles['slide-headline']} locale={locale} />
												<Subheadline data={item} className={styles['slide-subheadline']} locale={locale} />
											</div>
										</div>
									)}
								</SwiperSlide>
							),
					)
				) : (
					<>
						<SwiperSlide key={1}>
							<UaSkeleton key={1} variant="rectangular" height={320} />
							<UaSkeleton variant="text" height={25} />
							<UaSkeleton variant="text" height={15} />
						</SwiperSlide>
						<SwiperSlide key={2}>
							<UaSkeleton key={2} variant="rectangular" height={320} />
							<UaSkeleton variant="text" height={25} />
							<UaSkeleton variant="text" height={15} />
						</SwiperSlide>
						<SwiperSlide key={3}>
							<UaSkeleton key={3} variant="rectangular" height={320} />
							<UaSkeleton variant="text" height={25} />
							<UaSkeleton variant="text" height={15} />
						</SwiperSlide>
						<SwiperSlide key={4}>
							<UaSkeleton key={4} variant="rectangular" height={320} />
							<UaSkeleton variant="text" height={25} />
							<UaSkeleton variant="text" height={15} />
						</SwiperSlide>
					</>
				)}

				<span className={`${styles['slider-nav-wrap']}`} slot="container-end">
					<button
						type="button"
						className={`prev ${styles['slider-button']} ${styles.prev}`}
						disabled={slideProgress === 0}
					>
						{formatMessage('cms-slider-prev-slide')}
					</button>
					<button
						type="button"
						className={`next ${styles['slider-button']} ${styles.next}`}
						disabled={slideProgress === 1}
					>
						{formatMessage('cms-slider-next-slide')}
					</button>
				</span>
			</Swiper>
		</div>
	)
}

Bucket.Scattered = Scattered
Bucket.HorizontalScroll = HorizontalScroll

export default Bucket
