import dynamic from 'next/dynamic'
import { useMemo } from 'react'
import LazyLoad from 'react-lazy-load'
import Headline from '~/components/cms/legacy/partials/Headline'
import Subheadline from '~/components/cms/legacy/partials/Subheadline'
import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import { getCssColor } from '~/lib/cms/legacy/helper'
import MediaContent from '../partials/MediaContent'
import styles from './EmailSignup.module.scss'

const EmailForm = dynamic(() => import('~/components/shared/EmailSignup/EmailForm/EmailForm'), { ssr: false })

interface Props {
	data: CmTeasable
	position: number
	placement?: string
	basic?: boolean
	locale: string
}

const EmailSignup = ({ data, position, placement, basic, locale }: Props) => {
	const isPriority = position < 3
	const { desktopCss, mobileCss } = useMemo(() => getCssColor(data), [data])
	const merkleCode = data?.uaModuleSettings?.merkleSourceCode
	return basic ? (
		<section className={`${styles.email} ${styles.email__basic} ${mobileCss} ${desktopCss}`}>
			<div className={styles['email__heading-container']}>
				<Headline data={data} className={styles.email__header} locale={locale} />
				<Subheadline data={data} className={styles.email__subheader} locale={locale} />
			</div>

			<div className={styles['email__form-container']}>
				<LazyLoad>
					<EmailForm merkleCode={merkleCode} />
				</LazyLoad>
			</div>
		</section>
	) : (
		<section className={`${styles.email} ${styles.email__media} ${mobileCss} ${desktopCss}`}>
			<div className={styles.email__copy}>
				<Headline data={data} className={styles.email__header} locale={locale} />
				<Subheadline data={data} className={styles.subheadline} locale={locale} />

				<LazyLoad>
					<EmailForm merkleCode={merkleCode} />
				</LazyLoad>
			</div>

			<MediaContent
				locale={locale}
				data={data}
				priority={isPriority}
				placement={placement}
				imageRecipeDesktop="recipe-email-capture"
				imageRecipeMobile="recipe-email-capture"
				className={styles.email__image}
			/>
		</section>
	)
}

export default EmailSignup
