import CMLink from '~/components/cms/legacy/partials/CMLink'
import MediaItem from '~/components/cms/legacy/partials/MediaItem'
import type { CmTeasable, Maybe } from '~/graphql/generated/coremedia/type-document-node'

import styles from '~/components/cms/legacy/Bucket.module.scss'

interface BucketFourUpProps {
	module: string
	slides: Maybe<Maybe<CmTeasable>[]> | undefined
	placement?: string
	locale: string
}

/** Bucket Four Up is a 2x2 grid on mobile or a 1x4 on desktop, primarily used on the home page to feature categories */
const BucketFourUp = ({ module, slides, placement, locale }: BucketFourUpProps) => {
	return (
		<div className={`module__list ${styles.content__wrapper}`}>
			{slides &&
				slides.map(
					(slide, index) =>
						slide && (
							<div className={`${styles['image-primary']} module__list-item`} key={index}>
								<MediaItem
									locale={locale}
									data={slide}
									placement={placement}
									module={module}
									imageRecipeDesktop={`recipe-bucket-square`}
									imageRecipeMobile={`recipe-bucket-square`}
								/>
								<div className="module__list-item-content">
									<div className="module__link-text">
										<div className="module__ctas-wrapper ctaBlock--odd">
											<CMLink
												locale={locale}
												link={slide.teaserTargets?.[0]}
												parent={slide}
												placement={placement}
												module={module}
											/>
										</div>
									</div>
								</div>
							</div>
						),
				)}
		</div>
	)
}

export default BucketFourUp
