import { ProductTile } from '~/components/shared/ProductTile/ProductTile'
import type { MappedProductSlide } from '~/lib/client-server/cms/modules/content-carousel'

interface ProductSlideProps {
	productSlideData: MappedProductSlide
}

function ProductSlide({ productSlideData }: ProductSlideProps) {
	const { mappedProduct, faceOutColorsArray, useAdvancedTile } = productSlideData

	return (
		<ProductTile
			product={mappedProduct}
			faceOutColors={faceOutColorsArray}
			showColorInfo={useAdvancedTile}
			wishListEnabled={!!useAdvancedTile}
			badgesEnabled={useAdvancedTile}
			promotionsEnabled={useAdvancedTile}
			isExperimentalView
			inSwiper
		/>
	)
}

export default ProductSlide
