/* eslint-disable  @typescript-eslint/no-explicit-any */
import type { ContentCarouselModule } from '~/lib/client-server/cms/modules/content-carousel'
import { isCmUaBucket } from '~/lib/cms/legacy/type-guard'
import { CmCollectionImpl } from '../shared/collection'
import { logger } from '../shared/module'
import { isNonNullish } from '~/types/strict-null-helpers'

export default {
	identifier: (data: unknown): boolean => {
		if (isCmUaBucket(data)) {
			return data?.viewtype === 'bucket-content-carousel' || data?.viewtype === 'bucket-content-carousel-flush'
		}
		return false
	},

	transformer: (data: unknown, locale?: string): ContentCarouselModule | undefined => {
		// left the naming an file name the same... collection is important because it is a CM thing.. but not a fan of the mismatched info
		try {
			const carousel = new CmCollectionImpl(data, locale)
			return {
				type: 'carousel',
				id: carousel.getModuleId(),
				name: carousel.getModuleName(),
				theme: carousel.getModuleTheme(),
				layout: carousel.getCarouselLayout(),
				maxSlidesPerRow: carousel.getMaxSlidesPerRow(),
				slides: carousel.slides.map((slide) => slide.toSlide()).filter(isNonNullish),

				settings: {
					carouselStyle: carousel.getSliderStyle(),
					isCenter: carousel.isTextCentered(),
					isNumbered: carousel.hasNumberedSlides(),
					titleStyle: carousel.titleStyle(),
					descriptionStyle: carousel.descriptionStyle(),
				},

				title: carousel.getHeader(),
				description: carousel.getSubheader(),
				callsToAction: carousel.getTeaserTargets().map((t) => t.toUniversal()),

				mobile: {
					maxSlidesPerRow: carousel.getMaxSlidesPerRowMobile(),
				},
			}
		} catch (e) {
			logger.error(`Invalid input data received for rendering Content Carousel module: `, e)
			return undefined
		}
	},
}
