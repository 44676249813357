import type { StoryPlayerModule } from '~/lib/client-server/cms/modules/storyplayer'
import { isCmUaBucket } from '~/lib/cms/legacy/type-guard'
import { CmStoryPlayerImpl } from '../shared/storyplayer'
import { logger } from '../shared/module'

export default {
	identifier: (data: unknown): boolean => {
		if (isCmUaBucket(data)) {
			return data?.viewtype === 'bucket-story-player-sig'
		}
		return false
	},

	transformer: (data: unknown, locale?: string): StoryPlayerModule | undefined => {
		try {
			const storyplayer = new CmStoryPlayerImpl(data, locale)
			const desktopImage = storyplayer.getImage('desktop')
			const mobileImage = storyplayer.getImage('mobile') ?? desktopImage

			return {
				type: 'storyplayer',
				name: storyplayer.getModuleName() ?? '',
				theme: storyplayer.getModuleTheme(),
				posts: storyplayer.posts.map((post) => post.toPost()),

				profile: {
					name: storyplayer.authorName,
					image: storyplayer.authorImage ?? undefined,
				},

				media: desktopImage
					? {
							id: desktopImage.id,
							src: desktopImage.src,
							alt: desktopImage.alt,
							height: desktopImage.height,
							width: desktopImage.width,
					  }
					: undefined,
				title: storyplayer.getHeader(),
				description: storyplayer.getSubheader(),

				mobile: {
					media: mobileImage
						? {
								id: mobileImage.id,
								src: mobileImage.src,
								alt: mobileImage.alt,
								height: mobileImage.height,
								width: mobileImage.width,
						  }
						: undefined,
				},
			}
		} catch (e) {
			logger.error(`Invalid input data received for rendering Story Player module: `, e)
			return undefined
		}
	},
}
