import { setup, assign } from 'xstate'

export interface ContentSlotContext {
	/**
	 * Context to store personalized content
	 */
	personalizedContent: JSX.Element | null

	/**
	 * Context to track if the timer has finished
	 */
	timerFinished: boolean
}

// Define event type
export type ContentSlotEvent =
	| { type: 'PERSONALIZED_CONTENT_AVAILABLE'; content: JSX.Element | null } // Replace 'any' with a specific content type if known
	| { type: 'TIMER_FINISHED' }
	| { type: 'NAVIGATE' }

// Define the state machine
export const contentSlotStateMachine = setup({
	types: {
		context: <ContentSlotContext>{},
		input: <ContentSlotContext>{},
	},
	actions: {
		// Action to handle ignoring personalized content
		// This is used when the timer has already finished and we're showing the default content
		// The action is essentially a no-op (no operation), hence it assigns an empty object to the context
		// This helps in explicitly stating that personalized content should be ignored in this state
		ignorePersonalizedContent: assign({}),
	},
}).createMachine({
	id: 'contentSlotMachine', // Machine identifier
	initial: 'loading', // Initial state when the machine starts
	context: {
		personalizedContent: null,
		timerFinished: false,
	},
	states: {
		// There is a race between personalized content loading and the timer finishing
		// The machine will transition to the showingPersonalized state if personalized content is available before the timer finishes
		// Otherwise, it will transition to the showingDefault state if the timer finishes before personalized content is available
		// If personalized content loads after the timer finishes, it will be ignored
		loading: {
			// State when the page is loading and waiting for personalized content or timer to finish
			on: {
				PERSONALIZED_CONTENT_AVAILABLE: {
					target: 'showingPersonalized', // Transition to showingPersonalized state if content is available
					guard: ({ context }) => !context.timerFinished, // Only transition if the timer hasn't finished
				},
				TIMER_FINISHED: {
					target: 'showingDefault', // Transition to showingDefault state if the timer finishes
					actions: assign({ timerFinished: true }), // Update context to indicate timer has finished
				},
			},
		},
		showingDefault: {
			// State when showing the default content
			on: {
				PERSONALIZED_CONTENT_AVAILABLE: {
					actions: 'ignorePersonalizedContent', // Ignore personalized content if it becomes available after timer finishes
				},
				// In the event the page navigates in SPA mode, this will make sure to reset the machine
				NAVIGATE: {
					target: 'loading', // Transition back to loading state if user navigates away
					actions: assign({ timerFinished: false }), // Reset timerFinished context
				},
			},
		},
		showingPersonalized: {
			// State when showing the personalized content
			// No transitions as this is the final desired state when personalized content is available before timer finishes
			on: {
				// In the event the page navigates in SPA mode, this will make sure to reset the machine
				NAVIGATE: {
					target: 'loading', // Transition back to loading state if user navigates away
					actions: assign({ timerFinished: false }), // Reset timerFinished context
				},
			},
		},
	},
})
