import { useState, useEffect } from 'react'
import type { ContentCarouselModule, RenderableCarouselSlide } from '~/lib/client-server/cms/modules/content-carousel'
import { getCarouselSlides } from '~/lib/client-server/cms/sources/coremedia/shared/collection'
import { useLocale } from '~/components/hooks/useLocale'

export function useGetCMSModuleProductTiles(slides: ContentCarouselModule['slides']) {
	const locale = useLocale()
	const [carouselItems, setCarouselItems] = useState<Array<RenderableCarouselSlide>>([])

	useEffect(() => {
		const getCarouselItems = async () => {
			if (!slides) return

			const results = await getCarouselSlides(slides, locale)

			if (results) {
				setCarouselItems(results)
			}
		}

		getCarouselItems()
	}, [slides, locale])

	return { carouselItems }
}
