import styles from './CarouselNavigation.module.scss'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import ArrowIcon from '~/components/primitives/icons/ArrowIcon'

function CarouselNavigation() {
	const formatMessage = useFormatMessage()

	return (
		<span className={`${styles.carousel__navigation}`} slot="container-end">
			<button type="button" className={`prev`}>
				<span>{formatMessage('cms-slider-prev-slide')}</span>
				<ArrowIcon size="SM" />
			</button>

			<button type="button" className={`next`}>
				<span>{formatMessage('cms-slider-next-slide')}</span>
				<ArrowIcon size="SM" />
			</button>
		</span>
	)
}
CarouselNavigation.displayName = 'CarouselNavigation'
export default CarouselNavigation
