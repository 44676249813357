import { useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { getProductsFromTeasers } from '~/lib/cms/legacy/products-from-teasers'
import type { CmCollection, CmTeaserImpl, CmTeasable, Maybe } from '~/graphql/generated/coremedia/type-document-node'
import type { ClientProductTile } from 'lib/types/product.interface'
import type { CmProductTeaserSafe } from '~/lib/client-server/cms/sources/coremedia/shared/schemas'

/**
 * Reusable hook currently intended to be used in the CoreMedia Bucket components. This hook does the following: 
 * 1) Parses out the styleIds from CoreMedia data in order to query UACAPI for the items along with helpful meta data that determines what the final products and order of products are rendered
 * 2) Handles data fetching from UACAPI for those items
 * 3) Transforms the response into a data shape that can be injested by the <ProductTile /> component
 * 4) Using the meta data from step 1, ensures that any marketing content (aka CmTeasers) as well as variant products are rendered correctly and in the correct order as was originally designed.

 * @param teasableItems - An array of items from CoreMedia containing information about teasable items.
 * @returns carouselItems - An array of products tuned to be rendered by <ProductTile />
 */

export type MappedTeasableContent = Maybe<Maybe<CmTeasable>[]> | ClientProductTile[] | CmTeaserImpl[] | undefined

export function useProductsFromCollection(teasableItems: CmCollection['teasableItems'] | CmProductTeaserSafe[]) {
	const client = useApolloClient()
	const [carouselItems, setCarouselItems] = useState<MappedTeasableContent>()

	useEffect(() => {
		const fetchProductTileData = async () => {
			if (!teasableItems) return

			const results = await getProductsFromTeasers({ teasableItems, client })

			if (results) {
				setCarouselItems(results)
			}
		}

		fetchProductTileData()
	}, [client, teasableItems])

	return {
		carouselItems,
	}
}
