import { useMediaQuery } from '~/components/hooks/useMediaQuery'
import type { CmTeasable, ExtendedTeaserTarget, Maybe } from '~/graphql/generated/coremedia/type-document-node'
import logger from '~/lib/logger'

import cssVariables from '~/styles/variables.module.scss'
import CMLink from './CMLink'

interface Props {
	parent: CmTeasable
	teaserTargets: Maybe<Maybe<ExtendedTeaserTarget>[]> | undefined
	enableListWithCta?: boolean
	desktopTextLinks?: boolean
	mobileTextLinks?: boolean
	className?: string
	locale: string
}

const CallToAction = ({
	parent,
	teaserTargets,
	locale,
	enableListWithCta = false,
	desktopTextLinks = false,
	mobileTextLinks = false,
	className,
}: Props) => {
	try {
		const isDesktop: boolean = useMediaQuery(`(min-width:${cssVariables.smDesktop})`)

		if (!teaserTargets) return null

		const mobileButtonClass = mobileTextLinks ? 'cta__link' : 'cta__btn'
		const desktopButtonClass = desktopTextLinks ? 'cta__link' : 'cta__btn'

		let buttonClass = isDesktop ? desktopButtonClass : mobileButtonClass

		const activeCtas = teaserTargets.filter(
			// filter out inactive ctas
			(teaserTarget) => teaserTarget && teaserTarget.callToActionEnabled === true && teaserTarget.callToActionText,
		)

		if (enableListWithCta) {
			const firstActive = activeCtas.shift()
			// if category hero - send the first active element to last spot in array
			if (firstActive) activeCtas.push(firstActive)
			buttonClass = isDesktop ? 'cta__btn' : 'cta__link'
		}
		const ctaCountParity = activeCtas.length % 2 === 0 ? 'even' : 'odd'

		// determine if the CTA is needed
		if (activeCtas.length) {
			return (
				<ul className={`module__ctas-wrapper ctaBlock--${ctaCountParity} ${className}`}>
					{activeCtas.map((link, index) => {
						const className = enableListWithCta && activeCtas.length !== index + 1 ? 'cta__link' : buttonClass
						return (
							<li key={index}>
								<CMLink locale={locale} parent={parent} link={link} className={className}>
									{link?.callToActionText}
								</CMLink>
							</li>
						)
					})}
				</ul>
			)
		}
	} catch (err) {
		logger.error(err)
	}

	// otherwise return nothing
	return null
}
export default CallToAction
