interface ConditionalWrapperProps<T extends React.ElementType> {
	condition: boolean
	as: T
	children: React.ReactNode
}

/**
 * A Wrapper component that conditionally wraps children given a specific condition.
 * Useful when a condition impacts a Wrapper around children, but not the children themselves.
 * 
 * For example, consider the following logic:

  {isPreferred ? (
    <span>{formatMessage('selected-preferred-size')}</span>
  ) : (
    <Button type="submit">
      <span>{formatMessage('selected-preferred-size')}</span>
    </Button>
  )}

 * To clean up the JSX, you can use this component to achieve the same result:

  <ConditionalWrapper condition={isPreferred} as={Button} type="submit">
    <span>{formatMessage('selected-preferred-size')}</span>
  </ConditionalWrapper>
 */
function ConditionalWrapper<T extends React.ElementType>({
	condition,
	as,
	children,
	...rest
}: ConditionalWrapperProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof ConditionalWrapperProps<T>>) {
	const Element: any = as // eslint-disable-line @typescript-eslint/no-explicit-any -- TS checks the `as` type externally in the function signature
	return condition ? <Element {...rest}>{children}</Element> : <>{children}</>
}

export default ConditionalWrapper
