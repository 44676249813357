import clsx from 'clsx'
import React, { useCallback, useMemo } from 'react'
import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import { isLinkedSetting } from '~/lib/cms/legacy/type-guard'
import CallToAction from '../partials/CallToAction'
import Headline from '../partials/Headline'
import SnipeText from '../partials/SnipeText'
import Subheadline from '../partials/Subheadline'
import styles from './TextOnly.module.scss'

interface Props {
	data: CmTeasable
	locale: string
}

function getModuleSettings(data: CmTeasable) {
	const uaModuleSettings = data?.uaModuleSettings
	const mobileTeaserOverlay = uaModuleSettings?.mobileTeaserOverlay
	const desktopTeaserOverlay = uaModuleSettings?.teaserOverlay

	const defaultModuleSettings = {
		mobileCenterCopy: !!mobileTeaserOverlay?.centerCopy || false,
		mobileTextLinks: !!mobileTeaserOverlay?.useTextLinks || false,
		mobileWidth: mobileTeaserOverlay?.width || 100,
		desktopCenterCopy: !!desktopTeaserOverlay?.centerCopy || false,
		desktopTextLinks: !!desktopTeaserOverlay?.useTextLinks || false,
		desktopTwoColumn: !!desktopTeaserOverlay?.twoColumn || false,
		desktopWidth: desktopTeaserOverlay?.width || 100,
		headlineStyle: `${uaModuleSettings?.headlineStyle}` || 'headline1',
	}

	// Switch is used to set standard settings for legacy modules. This means we do not need settings in the module-settings-map.
	switch (data.viewtype) {
		case 'storytelling-text-1':
			return {
				...defaultModuleSettings,
				mobileCenterCopy: true,
				mobileTextLinks: true,
				desktopCenterCopy: true,
				desktopTextLinks: true,
				desktopWidth: 52,
				headlineStyle: 'headline2',
			}
		case 'storytelling-text-2':
			return {
				...defaultModuleSettings,
				mobileTextLinks: true,
				desktopTextLinks: true,
				desktopWidth: 60,
				headlineStyle: 'headline2',
			}
		case 'storytelling-quote-1':
			return {
				...defaultModuleSettings,
				mobileCenterCopy: true,
				mobileTextLinks: true,
				desktopCenterCopy: true,
				desktopTextLinks: true,
				desktopWidth: 64,
				headlineStyle: 'headline1',
			}
		default:
			return defaultModuleSettings
	}
}

const TextOnly = ({ data, locale }: Props) => {
	const getStyle = useCallback(
		(resolutionType: 'mobile' | 'desktop') => {
			const key =
				resolutionType === 'mobile'
					? data.uaModuleSettings?.mobileLinkedStyles
					: data.uaModuleSettings?.desktopLinkedStyles
			const linkedStyle = data?.uaModuleLinkSettings?.find((obj) => obj && obj.key === key)
			const setting = linkedStyle?.value?.find((obj) => obj && isLinkedSetting(obj) && obj?.description)
			return setting && isLinkedSetting(setting) ? `${setting.description}` : ''
		},
		[data],
	)

	const mobileStyle = useMemo(() => getStyle('mobile'), [getStyle])
	const desktopStyle = useMemo(() => getStyle('desktop'), [getStyle])
	const {
		mobileWidth,
		mobileCenterCopy,
		mobileTextLinks,
		desktopWidth,
		desktopCenterCopy,
		desktopTextLinks,
		desktopTwoColumn,
		headlineStyle,
	} = useMemo(() => getModuleSettings(data), [data])

	const contentWrapperStyle = useMemo(
		() =>
			({
				'--mobile-width': `${mobileWidth}%`,
				'--desktop-width': `${desktopWidth}%`,
			} as React.CSSProperties),
		[mobileWidth, desktopWidth],
	)

	const wrapperClasses = clsx('textbox', styles.textonly, {
		[styles['text-center-mb']]: mobileCenterCopy,
		[styles['dark-mb']]: mobileStyle === 'light-text',
		[styles['text-center-dt']]: desktopCenterCopy,
		[styles['dark-dt']]: desktopStyle === 'light-text',
	})

	const contentWrapperClasses = clsx(styles['content-wrapper'], {
		[styles['content-center-mb']]: mobileCenterCopy,
		[styles['content-center-dt']]: desktopCenterCopy || desktopTwoColumn,
	})

	return (
		<div data-cm-id={data.id} id={`cm-${data?.viewtype}`} className={wrapperClasses}>
			<div className={contentWrapperClasses} style={contentWrapperStyle}>
				{data.snipeText && <SnipeText data={data.snipeText} className={styles['snipe-text']} />}
				<Headline data={data} className={headlineStyle} locale={locale} />

				<div className={clsx({ [styles['two-column']]: desktopTwoColumn })}>
					<Subheadline data={data} locale={locale} />
					<CallToAction
						locale={locale}
						parent={data}
						teaserTargets={data.teaserTargets}
						desktopTextLinks={desktopTextLinks}
						mobileTextLinks={mobileTextLinks}
						className={styles.textonly__cta}
					/>
				</div>
			</div>
		</div>
	)
}

export default React.memo(TextOnly)
