import coremediaIcons from 'public/cm-icons-storytelling.svg?url'
import type { CmTeasable } from '~/graphql/generated/coremedia/type-document-node'
import { isLinkedSetting } from '~/lib/cms/legacy/type-guard'
import MediaContent from './MediaContent'

interface Props {
	data: CmTeasable
	enableCustomIcon?: boolean
	locale: string
}
const Icon = ({ data, locale, enableCustomIcon = false }: Props) => {
	const modifiedData = data

	const imageRecipeDesktop = 'recipe-collection-logo-sm'
	const imageRecipeMobile = 'recipe-collection-logo-sm'

	let iconSvg = ''
	if (data.uaModuleLinkSettings) {
		const setting = data.uaModuleLinkSettings.find((obj) => obj && obj.key === 'path:/linkedIcon')

		if (setting && setting.value) {
			const classCheckDesktop = setting.value.find((obj) => obj && isLinkedSetting(obj) && obj?.description)
			iconSvg = classCheckDesktop && isLinkedSetting(classCheckDesktop) ? `${classCheckDesktop.description}` : ''
		}
	}

	return enableCustomIcon ? (
		<MediaContent
			locale={locale}
			data={modifiedData}
			className={'module__logo'}
			mediaPosition={1}
			imageRecipeDesktop={enableCustomIcon && imageRecipeDesktop}
			imageRecipeMobile={enableCustomIcon && imageRecipeMobile}
		/>
	) : (
		<>
			{iconSvg ? (
				<svg xmlns="http://www.w3.org/2000/svg" className={`module__content-icon`}>
					<use xlinkHref={`${coremediaIcons}#${iconSvg}`} />
				</svg>
			) : (
				''
			)}
		</>
	)
}

export default Icon
