/* eslint-disable no-underscore-dangle */
import { standAloneApolloClient } from '~/lib/client-server/uacapi-client'
import { fetchExternalProductData, mapResponseToProductTileDataShape } from '~/lib/cms/legacy/products-from-teasers'
import { isProductUpc, isProductStyle } from '~/lib/products'
import type { MappedProductSlide, ProductSlide } from '~/lib/client-server/cms/modules/content-carousel'
import { isCmExternalProduct } from '~/lib/cms/legacy/type-guard'
import { ensureArray } from '~/types/strict-null-helpers'

export class CmContentSlideProduct {
	private _data

	constructor(product: unknown) {
		this._data = product // validate data here
	}

	public get isSkuOptionEnabled() {
		return !!this._data?.uaSettings?.style
	}

	public get productId() {
		const isExternalProduct = isCmExternalProduct(this._data)

		if (!isExternalProduct) return undefined

		return this.isSkuOptionEnabled ? this._data?.uaSettings?.style : this._data?.productRef.externalId
	}

	public get faceOutColor() {
		return this.isSkuOptionEnabled ? this._data?.uaSettings?.c_color : undefined
	}

	public get isProductUpc() {
		return this.productId ? isProductUpc(this.productId) : false
	}

	public get isProductStyle() {
		return this.productId ? isProductStyle(this.productId) : false
	}
}

/**
 * Retrieves product data from an external source based on the provided ProductSlide object.
 * @param productData The ProductSlide object containing necessary data for fetching the product.
 * @returns An object containing product data mapped to the data shape that <ProductTile /> expect and faceOutColor array if available, otherwise is an empty array. faceOutColor
 */
export async function getProductFromProductSlide(
	productData: ProductSlide,
	locale: string,
): Promise<MappedProductSlide | undefined> {
	const fetchedProduct = await fetchExternalProductData(
		{ unknown: productData.productId },
		standAloneApolloClient(locale),
	)

	if (!fetchedProduct) return undefined

	const mappedProduct = mapResponseToProductTileDataShape(fetchedProduct)

	if (!mappedProduct?.orderable) return undefined

	const faceOutColorsArray = ensureArray(productData.faceOutColor)

	return { mappedProduct, faceOutColorsArray, type: 'product', useAdvancedTile: productData.useAdvancedTile }
}
