import type { CmLinkable, PageGridPlacement } from '~/graphql/generated/coremedia/type-document-node'
import logger from '~/lib/logger'
import Accordion from './Accordion/Accordion'
import Article from './Article/Article'
import Bucket from './Bucket'
import EmailSignup from './EmailSignup/EmailSignup'
import GeneralBanner from './GeneralBanner'
import HeroMini from './HeroMini/HeroMini'
import InGrid from './InGrid'
import SeoGridLink from './SeoGridLink/SeoGridLink'
import Storytelling from './Storytelling/Storytelling'
import TextOnly from './TextOnly/TextOnly'
import Enrollment from './Enrollment/Enrollment'

interface PlacementProps {
	placement: PageGridPlacement['name']
	linkable: CmLinkable
	index: number
	locale: string
}

/**
 * The placement component has been deprecated in favor of ContentSlot which allows for more
 * flexibility in the data that can be used to render the component.  This component will continue
 * to exist as long as we have "Legacy" modules but will be removed once all modules have been
 * converted to the new format.
 * @param param0
 * @returns
 * @deprecated
 */
export default function Placement({ placement, linkable, index, locale }: PlacementProps) {
	switch (linkable.viewtype) {
		case 'dt-general-banner':
		case 'dt-general-banner-text-box':
		case 'dt-category-hero':
		case 'category-banner': // believe to be related to GeneralBanner: untested
		case 'dt-split-banner':
		case 'dt-split-banner-black':
		case 'hero-3section-4': // LISTED AS LEGACY IN CM CODE: LIKELY REMOVE
		case 'hero-singleimage-snipe-3': // LISTED AS LEGACY IN CM CODE: LIKELY REMOVE
		case 'dt-storytelling-split':
		case 'dt-storytelling-organic':
		case 'storytelling-full-image':
		case 'storytelling-split-image':
		case 'storytelling-split-1':
		case 'storytelling-split-2':
		case 'storytelling-organic-1':
		case 'storytelling-organic-2':
		case 'storytelling-quote-2':
			return <GeneralBanner data={linkable} placement={placement} position={index} locale={locale} />
		case 'dt-textBox':
		case 'storytelling-quote-1':
		case 'storytelling-text-1':
		case 'storytelling-text-2':
			return <TextOnly data={linkable} locale={locale} />
		case 'dt-storytelling-3up':
			return <Storytelling data={linkable} locale={locale} placement={placement} />
		case 'dt-bucket-3up':
		case 'dt-bucket-4up':
		case 'dt-bucket-3column':
		case 'dt-bucket-4column':
		case 'dt-bucket-router':
		case 'dt-bucket-router-wide':
			return <Bucket data={linkable} locale={locale} placement={placement} />
		case 'dt-bucket-scattered':
			return <Bucket.Scattered data={linkable} locale={locale} placement={placement} />
		case 'dt-horizontal-scroll':
			return <Bucket.HorizontalScroll data={linkable} locale={locale} placement={placement} />
		case 'single-in-grid-seo':
		case 'dt-in-grid-double':
		case 'dt-in-grid-single':
		case 'dt-in-grid-triple-split':
		case 'dt-in-grid-triple':
		case 'triple-in-grid-3':
		case 'triple-in-grid-1':
		case 'triple-in-grid-seo':
		case 'double-in-grid-seo-1':
		case 'single-in-grid':
			return <InGrid data={linkable} locale={locale} placement={placement} position={index} />
		case 'signup':
			return <EmailSignup data={linkable} locale={locale} placement={placement} position={index} />
		case 'signup-short':
			return <EmailSignup data={linkable} locale={locale} placement={placement} position={index} basic />
		case 'seo-grid-linking':
			return <SeoGridLink data={linkable} locale={locale} />
		case 'cm-article':
			return <Article data={linkable} locale={locale} placement={placement} />
		case 'hero01-mini':
			return <HeroMini data={linkable} locale={locale} placement={placement} position={index} />
		case 'accordion-collection':
			return <Accordion data={linkable} locale={locale} placement={placement} />
		case 'enrollment':
			return <Enrollment data={linkable} locale={locale} placement={placement} />
		default:
			logger.info(`Invalid CoreMedia module encoutered: ${linkable.viewtype}`)
			// eslint-disable-next-line local-rules/disallow-untranslated-literals-in-jsx
			return <div>error: Unknown module type: {linkable.viewtype}</div>
	}
}
